import Vue from 'vue';
import chatsApi from '@/api/chats';
import messagesApi from '@/api/messages';
import {
  getExistenceByKeyValue,
  getObjectByKeyValue,
  getObjectIndexByKeyValue,
} from '@/lib/utils';
import { DEPARTMENTS, OBSOLETE_FOLDER_IDS, FEATURES } from '../../lib/const';
import { SEARCH_TYPE } from '../types';

const state = {
  isBotBlocked: false,
  chats: [],
  currentChatId: 0,
  counts: {
    all: 0,
    new: 0,
    newVIP: 0,
    newUzb: 0,
    newParking: 0,

    ...DEPARTMENTS.filter(department => !department.hasComplexFilter).reduce(
      (acc, curr) => {
        return { ...acc, [curr.id]: 0 };
      },
      {}
    ),
  },

  folders: [
    {
      label: 'Все',
      name: 'all',
      isDepartment: false,
      filter() {
        return state.chats;
      },
    },

    {
      label: 'Новые',
      name: 'new',
      isDepartment: false,
      filter() {
        return state.chats.filter(
          chat =>
            !chat.isVIP &&
            !chat.isRead &&
            !chat.departments.includes('uzb') &&
            !chat.departments.includes('parking')
        );
      },
    },

    {
      label: 'Новые VIP',
      name: 'newVIP',
      isDepartment: false,
      filter() {
        return state.chats.filter(chat => chat.isVIP && !chat.isRead);
      },
    },

    {
      label: 'Новые UZB',
      name: 'newUzb',
      icon: '🇺🇿',
      isDepartment: false,
      filter() {
        return state.chats.filter(
          chat => !chat.isRead && chat.departments.indexOf('uzb') >= 0
        );
      },
    },

    {
      label: 'Новые Автопарки',
      name: 'newParking',
      icon: 'mdi-parking',
      isDepartment: false,
      filter() {
        return state.chats.filter(
          chat => !chat.isRead && chat.departments.indexOf('parking') >= 0
        );
      },
    },

    ...DEPARTMENTS.filter(department => !department.hasComplexFilter).map(
      ({ id, name, color, icon }) => ({
        label: name,
        name: id,
        color,
        icon,
        isDepartment: true,
        filter() {
          return state.chats.filter(chat => chat.departments.indexOf(id) >= 0);
        },
      })
    ),
  ],

  chatModes: {
    standard: '',
    editMessage: 'editMessage',
  },
  chatMode: '',
  replyToMessage: {},
  forwardFrom: 0,
  forwardTo: 0,
  forwardMessages: [],
  selectedMessages: [],
  currentMessage: {},
  scrollToMessageId: -1,
  messageInput: '',
  searchInput: '',
  searchType: SEARCH_TYPE.USER,
  searchDate: '',
  currentFolder: 'all',
  sequenceSort:
    JSON.parse(window.localStorage.getItem('sequenceSort')) || false,
  messageReplyWaitTime: { vip: 5, regular: 15 },
  activeChatChangeInProgress: false,
  byId: {},
  chatIds: [],
  fetchingMessages: false,
};

const getters = {
  isBotBlocked(state) {
    return state.isBotBlocked;
  },
  regularFolders(state) {
    return state.folders.filter(f => !f.isDepartment);
  },

  departmentFolders(state, getters, rootState, rootGetters) {
    if (
      rootGetters['feature/isFeatureEnabled'](FEATURES.HIDE_OBSOLETE_FOLDERS)
    ) {
      return state.folders.filter(
        f => f.isDepartment && !OBSOLETE_FOLDER_IDS.includes(f.name)
      );
    }

    return state.folders.filter(f => f.isDepartment);
  },

  sortedChats(state) {
    let chats = [...state.chats];

    if (state.searchInput.length) {
      chats = chats.filter(f => {
        return (
          f.userIds?.some(id => {
            return (
              id
                .toLocaleLowerCase()
                .indexOf(state.searchInput.toLocaleLowerCase()) >= 0
            );
          }) ||
          (f.from.first_name &&
            f.from.first_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.from.last_name &&
            f.from.last_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.from.first_name &&
            f.from.last_name &&
            f.from.first_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase().split(' ')[0]) >=
              0 &&
            f.from.last_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase().split(' ')[1]) >=
              0) ||
          (f.from.username &&
            f.from.username
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.info &&
            f.info
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.additionalInfo &&
            f.additionalInfo
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0)
        );
      });
    } else {
      chats = [
        ...state.folders
          .filter(f => state.currentFolder === f.name)[0]
          .filter(),
      ];
    }

    chats.sort((chatOne, chatTwo) => {
      let chatOneLastMessageTimestamp =
        chatOne.messages[chatOne.messages.length - 1]?.createdAt || 0;
      let chatTwoLastMessageTimestamp =
        chatTwo.messages[chatTwo.messages.length - 1]?.createdAt || 0;

      if (state.sequenceSort) {
        chatOneLastMessageTimestamp = chatOne.sortMessage?.createdAt || 0;
        chatTwoLastMessageTimestamp = chatTwo.sortMessage?.createdAt || 0;
      }

      return chatTwoLastMessageTimestamp - chatOneLastMessageTimestamp;
    });

    return chats;
  },

  currentChat(state, getters, rootState, rootGetters) {
    if (rootGetters['feature/isFeatureEnabled']('CHATS_V2')) {
      return state.byId[state.currentChatId] || {};
    }

    return (
      state.chats.filter(chat => chat.chatId === state.currentChatId)[0] || {}
    );
  },

  isStandardMode(state) {
    return state.chatMode === '';
  },

  isMessageEditMode(state) {
    return state.chatMode === 'editMessage';
  },

  messages(state, getters) {
    return getters.currentChat.messages || [];
  },

  selectedMessagesIds(state) {
    return state.selectedMessages.map(x => x._id);
  },

  hasSelectedMessages(state) {
    return state.selectedMessages.length > 0;
  },

  filteredBySearchChatIds(state) {
    if (!state.searchInput.length) return state.chatIds;

    const searchPhraseLowercase = state.searchInput.toLocaleLowerCase();
    const [firstWord, secondWord] = searchPhraseLowercase.split(' ');

    return state.chatIds.filter(chatId => {
      const chat = state.byId[chatId];
      const firstName = chat.from.first_name?.toLocaleLowerCase();
      const lastName = chat.from.last_name?.toLocaleLowerCase();

      return (
        chat.userIds?.some(id =>
          id.toLocaleLowerCase().includes(searchPhraseLowercase)
        ) ||
        firstName?.includes(searchPhraseLowercase) ||
        lastName?.includes(searchPhraseLowercase) ||
        (firstName &&
          lastName &&
          firstName.includes(firstWord) &&
          lastName.includes(secondWord)) ||
        chat.from.username
          ?.toLocaleLowerCase()
          .includes(searchPhraseLowercase) ||
        chat.info?.toLocaleLowerCase().includes(searchPhraseLowercase) ||
        chat.additionalInfo?.toLocaleLowerCase().includes(searchPhraseLowercase)
      );
    });
  },

  filteredChatIds(state, getters) {
    if (state.searchInput.length) {
      return getters.filteredBySearchChatIds;
    }

    const { currentFolder } = state;

    if (currentFolder === 'all') {
      return state.chatIds;
    }

    if (
      DEPARTMENTS.find(
        department =>
          !department.hasComplexFilter && department.id === currentFolder
      )
    ) {
      return state.chatIds.filter(chatId => {
        const chat = state.byId[chatId];
        return chat.departments.indexOf(currentFolder) >= 0;
      });
    }

    if (currentFolder === 'new') {
      return state.chatIds.filter(chatId => {
        const chat = state.byId[chatId];
        return (
          !chat.isVIP &&
          !chat.isRead &&
          !chat.departments.includes('uzb') &&
          !chat.departments.includes('parking')
        );
      });
    }

    if (currentFolder === 'newVIP') {
      return state.chatIds.filter(chatId => {
        const chat = state.byId[chatId];
        return chat.isVIP && !chat.isRead;
      });
    }

    if (currentFolder === 'newUzb') {
      return state.chatIds.filter(chatId => {
        const chat = state.byId[chatId];
        return !chat.isRead && chat.departments.indexOf('uzb') >= 0;
      });
    }

    if (currentFolder === 'newParking') {
      return state.chatIds.filter(chatId => {
        const chat = state.byId[chatId];
        return !chat.isRead && chat.departments.indexOf('parking') >= 0;
      });
    }
  },
};

const mutations = {
  SET_CHATS(state, { chats }) {
    const chatIds = state.chats.map(chat => chat.chatId);
    chats.forEach(chat => {
      if (chatIds.indexOf(chat.chatId) < 0) state.chats.push(chat);
    });
  },

  SET_IS_BOT_BLOCKED(state, status) {
    state.isBotBlocked = status;
  },

  SET_CURRENT_CHAT_ID(state, chatId) {
    state.currentChatId = chatId;
  },

  ADD_CHAT(state, chat) {
    let hasChat = !!getObjectByKeyValue(state.chats, chat.chatId, 'chatId');
    if (hasChat) return;

    state.chats.push(chat);
  },

  ADD_CHAT_V2(state, chat) {
    const { chatId, ...rest } = chat;

    if (!state.byId[chatId]) {
      Vue.set(state.byId, chatId, { chatId, ...rest });
      state.chatIds.unshift(chatId);
    }
  },

  UPDATE_CHAT(state, from) {
    let chat = getObjectByKeyValue(state.chats, from.id, 'chatId');
    chat.from = from;
  },

  UPDATE_CHAT_V2(state, from) {
    const chat = state.byId[from.id];
    if (chat) {
      Vue.set(chat, 'from', from);
    }
  },

  SET_CHAT_COUNTS(state, counts) {
    Object.keys(state.counts).forEach(countKey => {
      state.counts[countKey] = counts[countKey];
    });
  },

  SET_CHAT_SORT_MESSAGE(state, sortMessage) {
    let chat = getObjectByKeyValue(state.chats, sortMessage.chatId, 'chatId');
    if (chat) chat.sortMessage = sortMessage;
  },

  SET_CHAT_SORT_MESSAGE_V2(state, sortMessage) {
    const chat = state.byId[sortMessage.chatId];
    if (chat) {
      Vue.set(chat, 'sortMessage', sortMessage);

      if (state.sequenceSort) {
        state.chatIds.sort((chatIdA, chatIdB) => {
          const { sortMessage: sortMessageA } = state.byId[chatIdA];
          const { sortMessage: sortMessageB } = state.byId[chatIdB];

          return (
            (sortMessageB?.createdAt || 0) - (sortMessageA?.createdAt || 0)
          );
        });
      }
    }
  },

  SET_UNREAD_MESSAGES_COUNT(state, { chatId, count }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.unreadMessagesCount = count;
  },

  SET_UNREAD_MESSAGES_COUNT_V2(state, { chatId, count }) {
    const chat = state.byId[chatId];
    if (chat) {
      chat.unreadMessagesCount = count;
    }
  },

  ADD_CHAT_MESSAGE(state, message) {
    let chat = getObjectByKeyValue(state.chats, message.chatId, 'chatId');
    if (chat) {
      let messageIds = chat.messages.map(x => x.messageId);
      if (messageIds.indexOf(message.messageId) < 0) {
        chat.messages.push(message);
        chat.messages.sort(
          (messageOne, messageTwo) =>
            messageOne.createdAt - messageTwo.createdAt
        );
      }
    }
  },

  ADD_CHAT_MESSAGE_V2(state, message) {
    const chat = state.byId[message.chatId];
    if (chat) {
      if (
        !chat.messages.find(
          chatMessage => chatMessage.messageId === message.messageId
        )
      ) {
        chat.messages.push(message);

        if (!state.sequenceSort) {
          // check if chat is already the last one in chatIds - if yes don't sort
          if (state.chatIds[0] === message.chatId) return;

          state.chatIds.sort((chatIdA, chatIdB) => {
            const { messages: messagesA } = state.byId[chatIdA];
            const { messages: messagesB } = state.byId[chatIdB];

            return (
              (messagesB[messagesB.length - 1]?.createdAt || 0) -
              (messagesA[messagesA.length - 1]?.createdAt || 0)
            );
          });
        }
      }
    }
  },

  EDIT_MESSAGE(state, fields) {
    let chat = getObjectByKeyValue(state.chats, fields.chatId, 'chatId');

    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        fields.messageId,
        'messageId'
      );

      if (messageIndex === null) return;

      const removeReplyToMessageField = field => {
        for (let i = chat.messages.length - 1; i >= 0; i--) {
          let message = chat.messages[i];
          let isEditedMessage =
            message.replyToMessage &&
            message.replyToMessage.messageId === fields.messageId;

          if (isEditedMessage) Vue.delete(message.replyToMessage, field);
        }
      };
      const mediaProperties = [
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];
      const findMediaType = message => {
        let messageKeys = Object.keys(message);

        for (let i = 0; i < messageKeys.length; i++) {
          if (mediaProperties.indexOf(messageKeys[i]) >= 0)
            return messageKeys[i];
        }

        return null;
      };
      const handleTypeChange = (currentMessage, editedMessage) => {
        const currentMessageMediaType = findMediaType(currentMessage);
        const editedMessageMediaType = findMediaType(editedMessage);

        if (!currentMessageMediaType || !editedMessageMediaType) return;

        if (currentMessageMediaType !== editedMessageMediaType) {
          delete chat.messages[messageIndex][currentMessageMediaType];
          removeReplyToMessageField(currentMessageMediaType);
        }
      };

      if (findMediaType(chat.messages[messageIndex])) {
        if (
          chat.messages[messageIndex].hasOwnProperty('text') &&
          Object.keys(fields).indexOf('text') < 0
        ) {
          delete chat.messages[messageIndex]['text'];
          removeReplyToMessageField('text');
        }
      }

      handleTypeChange(chat.messages[messageIndex], fields);

      Object.keys(fields).forEach(field => {
        Vue.set(chat.messages[messageIndex], field, fields[field]);
      });

      const messageContentFields = [
        'text',
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];

      //set changed fields for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isEditedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === fields.messageId;

        if (isEditedMessage) {
          const fieldsToChange = Object.keys(fields).filter(
            field => messageContentFields.indexOf(field) >= 0
          );

          fieldsToChange.forEach(field => {
            Vue.set(message.replyToMessage, field, fields[field]);
          });
        }
      }
    }
  },

  EDIT_MESSAGE_V2(state, fields) {
    // TODO: refactor/optimize
    const chat = state.byId[fields.chatId];

    if (chat) {
      const messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        fields.messageId,
        'messageId'
      );

      if (messageIndex === null) return;

      const removeReplyToMessageField = field => {
        for (let i = chat.messages.length - 1; i >= 0; i--) {
          let message = chat.messages[i];
          let isEditedMessage =
            message.replyToMessage &&
            message.replyToMessage.messageId === fields.messageId;

          if (isEditedMessage) Vue.delete(message.replyToMessage, field);
        }
      };
      const mediaProperties = [
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];
      const findMediaType = message => {
        let messageKeys = Object.keys(message);

        for (let i = 0; i < messageKeys.length; i++) {
          if (mediaProperties.indexOf(messageKeys[i]) >= 0)
            return messageKeys[i];
        }

        return null;
      };
      const handleTypeChange = (currentMessage, editedMessage) => {
        const currentMessageMediaType = findMediaType(currentMessage);
        const editedMessageMediaType = findMediaType(editedMessage);

        if (!currentMessageMediaType || !editedMessageMediaType) return;

        if (currentMessageMediaType !== editedMessageMediaType) {
          delete chat.messages[messageIndex][currentMessageMediaType];
          removeReplyToMessageField(currentMessageMediaType);
        }
      };

      if (findMediaType(chat.messages[messageIndex])) {
        if (
          chat.messages[messageIndex].hasOwnProperty('text') &&
          Object.keys(fields).indexOf('text') < 0
        ) {
          delete chat.messages[messageIndex]['text'];
          removeReplyToMessageField('text');
        }
      }

      handleTypeChange(chat.messages[messageIndex], fields);

      Object.keys(fields).forEach(field => {
        Vue.set(chat.messages[messageIndex], field, fields[field]);
      });

      const messageContentFields = [
        'text',
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];

      //set changed fields for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isEditedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === fields.messageId;

        if (isEditedMessage) {
          const fieldsToChange = Object.keys(fields).filter(
            field => messageContentFields.indexOf(field) >= 0
          );

          fieldsToChange.forEach(field => {
            Vue.set(message.replyToMessage, field, fields[field]);
          });
        }
      }
    }
  },

  DELETE_MESSAGE(state, { chatId, messageId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      if (messageIndex) chat.messages.splice(messageIndex, 1);

      if (chat.messages.length === 0) return;

      //set isRemoved for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isRemovedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === messageId;

        if (isRemovedMessage) message.replyToMessage.isRemoved = true;
      }
    }
  },

  DELETE_MESSAGE_V2(state, { chatId, messageId }) {
    const chat = state.byId[chatId];
    if (chat) {
      const messageIndex = chat.messages.findIndex(
        message => message.messageId === messageId
      );
      if (messageIndex >= 0) chat.messages.splice(messageIndex, 1);

      if (chat.messages.length === 0) return;

      //set isRemoved for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        const message = chat.messages[i];
        const isRemovedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === messageId;

        if (isRemovedMessage) message.replyToMessage.isRemoved = true;
      }
    }
  },

  SET_MESSAGE_COMMENT(state, { chatId, messageId, comment }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      if (messageIndex !== null)
        Vue.set(chat.messages[messageIndex], 'comment', comment);
    }
  },

  SET_MESSAGE_COMMENT_V2(state, { chatId, messageId, comment }) {
    const chat = state.byId[chatId];
    if (chat) {
      const messageIndex = chat.messages.findIndex(
        message => message.messageId === messageId
      );

      if (messageIndex >= 0) {
        Vue.set(chat.messages[messageIndex], 'comment', comment);
      }
    }
  },

  SET_MESSAGE_COLOR(state, { chatId, messageId, color }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      if (messageIndex !== null)
        Vue.set(chat.messages[messageIndex], 'color', color);
    }
  },

  SET_MESSAGE_COLOR_V2(state, { chatId, messageId, color }) {
    const chat = state.byId[chatId];
    if (chat) {
      const messageIndex = chat.messages.findIndex(
        message => message.messageId === messageId
      );

      if (messageIndex >= 0) {
        Vue.set(chat.messages[messageIndex], 'color', color);
      }
    }
  },

  SET_CHAT_MODE(state, mode = '') {
    if (Object.values(state.chatModes).indexOf(mode) >= 0)
      state.chatMode = mode;
  },

  SET_REPLY_TO_MESSAGE(state, message = {}) {
    if (Object.keys(message).length > 0) {
      let chat = getObjectByKeyValue(state.chats, message.chatId, 'chatId');
      message.from = chat.from;
    }

    state.replyToMessage = message;
  },

  ADD_SELECTED_MESSAGE(state, message) {
    let messageExists = getExistenceByKeyValue(
      state.selectedMessages,
      message._id,
      '_id'
    );
    if (!messageExists) state.selectedMessages.push(message);
  },

  DELETE_FROM_SELECTED_MESSAGES(state, message) {
    let messageIndex = getObjectIndexByKeyValue(
      state.selectedMessages,
      message._id,
      '_id'
    );
    if (messageIndex >= 0) state.selectedMessages.splice(messageIndex, 1);
  },

  SET_SELECTED_MESSAGES(state, messages = []) {
    state.selectedMessages = messages;
  },

  SET_CURRENT_MESSAGE(state, message = {}) {
    state.currentMessage = message;
  },

  SET_MESSAGE_INPUT(state, text) {
    state.messageInput = text;
  },

  SET_SEARCH_INPUT(state, text) {
    state.searchInput = text;
  },

  SET_SEQUENCE_SORT(state, { sequenceSort, isChatsV2Enabled }) {
    window.localStorage.setItem('sequenceSort', JSON.stringify(sequenceSort));
    state.sequenceSort = sequenceSort;
    if (isChatsV2Enabled) {
      state.chatIds.sort((chatIdA, chatIdB) => {
        const { messages: messagesA, sortMessage: sortMessageA } =
          state.byId[chatIdA];
        const { messages: messagesB, sortMessage: sortMessageB } =
          state.byId[chatIdB];

        if (state.sequenceSort) {
          return (
            (sortMessageB?.createdAt || 0) - (sortMessageA?.createdAt || 0)
          );
        }

        return (
          (messagesB[messagesB.length - 1]?.createdAt || 0) -
          (messagesA[messagesA.length - 1]?.createdAt || 0)
        );
      });
    }
  },

  SET_CURRENT_FOLDER(state, folderName) {
    state.currentFolder = folderName;
  },

  SET_FORWARD_FROM(state, chatId = 0) {
    state.forwardFrom = chatId;
  },

  SET_FORWARD_TO(state, chatId = 0) {
    state.forwardTo = chatId;
  },

  SET_FORWARD_MESSAGES(state, messages = []) {
    state.forwardMessages = messages;
  },

  SET_INFO(state, { chatId, info }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.info = info;
  },

  SET_INFO_V2(state, { chatId, info }) {
    if (state.byId[chatId]) {
      state.byId[chatId].info = info;
    }
  },

  SET_ADDITIONAL_INFO(state, { chatId, additionalInfo }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.additionalInfo = additionalInfo;
  },

  SET_ADDITIONAL_INFO_V2(state, { chatId, additionalInfo }) {
    if (state.byId[chatId]) {
      state.byId[chatId].additionalInfo = additionalInfo;
    }
  },

  SET_CREDENTIALS(state, { chatId, credentials }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.credentials = credentials;
  },

  SET_CREDENTIALS_V2(state, { chatId, credentials }) {
    if (state.byId[chatId]) {
      state.byId[chatId].credentials = credentials;
    }
  },

  SET_USER_IDS(state, { chatId, userIds }) {
    const chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.userIds = userIds;
  },

  SET_USER_IDS_V2(state, { chatId, userIds }) {
    if (state.byId[chatId]) {
      state.byId[chatId].userIds = userIds;
    }
  },

  SET_VIP_STATUS(state, { chatId, isVIP }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.isVIP = isVIP;
  },

  SET_VIP_STATUS_V2(state, { chatId, isVIP }) {
    if (state.byId[chatId]) {
      state.byId[chatId].isVIP = isVIP;
    }
  },

  SET_READ_STATUS(state, { chatId, isRead }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.isRead = isRead;
  },

  SET_READ_STATUS_V2(state, { chatId, isRead }) {
    if (state.byId[chatId]) {
      state.byId[chatId].isRead = isRead;
    }
  },

  SET_APPROVED_BY(state, { chatId, supportId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.approvedBy = supportId;
  },

  SET_APPROVED_BY_V2(state, { chatId, supportId }) {
    if (state.byId[chatId]) {
      state.byId[chatId].approvedBy = supportId;
    }
  },

  SET_BLOCKED_BY(state, { chatId, supportId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.blockedBy = supportId;
  },

  SET_BLOCKED_BY_V2(state, { chatId, supportId }) {
    if (state.byId[chatId]) {
      state.byId[chatId].blockedBy = supportId;
    }
  },

  SET_DEPARTMENTS(state, { chatId, departments }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.departments = departments;
  },

  SET_DEPARTMENTS_V2(state, { chatId, departments }) {
    if (state.byId[chatId]) {
      state.byId[chatId].departments = departments;
    }
  },

  SET_CHAT_LANGUAGE(state, { chatId, language }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.language = language;
  },

  SET_CHAT_LANGUAGE_V2(state, { chatId, language }) {
    if (state.byId[chatId]) {
      state.byId[chatId].language = language;
    }
  },

  //not used
  SET_START_CHAT(state, { chatId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.scenario = 'supportScenario';
  },

  //not used
  SET_END_CHAT(state, { chatId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.scenario = '';
  },

  SET_SCROLL_TO_MESSAGE_ID(state, { messageId }) {
    state.scrollToMessageId = messageId;
  },

  SET_SEARCH_TYPE(state, { searchType }) {
    state.searchType = searchType;
  },

  SET_SEARCH_DATE(state, { searchDate }) {
    state.searchDate = searchDate;
  },

  SET_MESSAGE_REPLY_WAIT_TIME(state, messageReplyWaitTime) {
    state.messageReplyWaitTime = messageReplyWaitTime;
  },

  SET_ACTIVE_CHAT_CHANGE_IN_PROGRESS(state, inProgress) {
    state.activeChatChangeInProgress = inProgress;
  },

  NORMALIZE_CHATS(state) {
    state.chats.sort((chatA, chatB) => {
      const { messages: messagesA, sortMessage: sortMessageA } = chatA;
      const { messages: messagesB, sortMessage: sortMessageB } = chatB;

      if (state.sequenceSort) {
        return (sortMessageB?.createdAt || 0) - (sortMessageA?.createdAt || 0);
      }

      return (
        (messagesB[messagesB.length - 1]?.createdAt || 0) -
        (messagesA[messagesA.length - 1]?.createdAt || 0)
      );
    });

    state.chats.forEach(chat => {
      const { chatId, ...rest } = chat;
      state.chatIds.push(chatId);
      Vue.set(state.byId, chatId, { chatId, ...rest });
    });
  },

  UPDATE_FIELD(state, { chatId, key, value }) {
    const chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      Vue.set(chat, key, value);
    }
  },

  UPDATE_FIELD_V2(state, { chatId, key, value }) {
    if (state.byId[chatId]) {
      Vue.set(state.byId[chatId], key, value);
    }
  },

  SET_FETCHING_MESSAGES(state, value) {
    state.fetchingMessages = value;
  },
};

const actions = {
  //Api calls
  async get_chats({ commit }, payload = {}) {
    try {
      const { data } = await chatsApi.getAll({
        limit: payload.limit || 20,
        skip: payload.skip || 0,
      });

      const { chatsWithMessages, chatsWithNoMessages } = data.reduce(
        (acc, chat) => {
          if (chat?.messages.length > 0) {
            acc.chatsWithMessages.push(chat);
            return acc;
          }

          acc.chatsWithNoMessages.push(chat);
          return acc;
        },
        { chatsWithMessages: [], chatsWithNoMessages: [] }
      );

      if (chatsWithNoMessages.length)
        console.log('chatsWithNoMessages', chatsWithNoMessages);

      commit('SET_CHATS', { chats: chatsWithMessages });
    } catch (e) {
      console.log(e);
    }
  },

  //not used
  get_chat({ commit, state }, chatId) {
    return new Promise(async (resolve, reject) => {
      try {
        let chatResponse = await chatsApi.getOne(chatId);
        let chat = chatResponse.data;
        if (chat.messages && chat.messages.length === 0)
          throw 'Chat has no messages';

        commit('ADD_CHAT', chat);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  async get_more_chat_messages({ commit, state }, { chatId, skip }) {
    commit('SET_FETCHING_MESSAGES', true);

    try {
      let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');

      let messagesResponse = await messagesApi.getAll(chatId, {
        limit: 50,
        skip: typeof skip === 'number' ? skip : chat.messages.length,
      });

      let messageIds = chat.messages.map(x => x.messageId);
      let newMessages = messagesResponse.data.filter(
        x => messageIds.indexOf(x.messageId) < 0
      );

      let allMessages = [...newMessages, ...chat.messages];
      chat.messages = allMessages.sort(
        (messageOne, messageTwo) => messageOne.createdAt - messageTwo.createdAt
      );

      return newMessages;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      commit('SET_FETCHING_MESSAGES', false);
    }
  },

  async get_more_chat_messages_v2({ commit, state }, { chatId, skip }) {
    commit('SET_FETCHING_MESSAGES', true);

    try {
      const chat = state.byId[chatId];

      const messagesResponse = await messagesApi.getAll(chatId, {
        limit: 50,
        skip: typeof skip === 'number' ? skip : chat.messages.length,
      });

      const messageIds = chat.messages.map(x => x.messageId);
      const newMessages = messagesResponse.data.filter(
        x => messageIds.indexOf(x.messageId) < 0
      );

      const allMessages = [...newMessages, ...chat.messages].sort(
        (messageOne, messageTwo) => messageOne.createdAt - messageTwo.createdAt
      );

      chat.messages = allMessages;

      return newMessages;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      commit('SET_FETCHING_MESSAGES', false);
    }
  },

  get_chat_counts({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        let counts = await chatsApi.getCounts();
        commit('SET_CHAT_COUNTS', counts.data);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  // get_chat_unread_messages_count({ state, commit }, chatId) {
  //     return messagesApi.getUnreadCount(chatId);
  // },

  set_message_comment({ commit, dispatch }, payload) {
    return messagesApi.comment(
      payload.messageId,
      payload.chatId,
      payload.comment
    );
  },

  edit_message({ commit }, payload) {
    return messagesApi.edit(payload.messageId, payload.chatId, payload.text);
  },

  delete_message({ commit }, payload) {
    return messagesApi.delete(payload.messageId, payload.chatId);
  },

  set_color({ commit }, payload) {
    return messagesApi.color(payload.messageId, payload.chatId, payload.color);
  },

  set_info({ commit }, payload) {
    return chatsApi.info(payload.chatId, payload.info, payload.supportId);
  },

  set_additional_info({ commit }, payload) {
    return chatsApi.additionalInfo(
      payload.chatId,
      payload.additionalInfo,
      payload.supportId
    );
  },

  set_credentials({ commit }, payload) {
    return chatsApi.credentials(
      payload.chatId,
      payload.credentials,
      payload.supportId
    );
  },

  set_user_ids({ commit }, payload) {
    const { chatId, userIds } = payload;

    return chatsApi.setUserIds(chatId, userIds);
  },

  set_approved_by({ commit, dispatch }, payload) {
    return chatsApi.approve(payload.chatId, payload.supportId);
  },

  set_blocked_by({ commit, dispatch }, payload) {
    return chatsApi.block(payload.chatId, payload.supportId);
  },

  set_vip_status({ commit, dispatch }, payload) {
    return chatsApi.vip(payload.chatId, payload.isVIP);
  },

  set_read_status({ commit, dispatch }, payload) {
    return chatsApi.read(payload.chatId, payload.supportId, payload.isRead);
  },

  set_departments({ commit, dispatch }, payload) {
    return chatsApi.departments(payload.chatId, payload.departments);
  },

  set_start_chat({ commit, dispatch }, payload) {
    return chatsApi.startChat(payload.chatId);
  },

  set_end_chat({ commit, dispatch }, payload) {
    return chatsApi.endChat(payload.chatId);
  },

  get_message_reply_wait_time({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const messageReplyWaitTime =
          await messagesApi.getMessageReplyWaitTime();
        commit('SET_MESSAGE_REPLY_WAIT_TIME', messageReplyWaitTime.data);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  async enter_active_chat({ commit, dispatch, state, rootGetters }, chatId) {
    if (state.activeChatChangeInProgress) return;

    const currentSupportId = rootGetters['support/currentSupport']?._id;
    if (!currentSupportId) return;

    commit('SET_ACTIVE_CHAT_CHANGE_IN_PROGRESS', true);
    commit('SET_IS_BOT_BLOCKED', false);
    commit('SET_CURRENT_CHAT_ID', chatId);
    commit('ui/ADD_ACTION', 'open-chat', { root: true });

    try {
      await Promise.all([
        dispatch(
          'support/set_active_chats',
          {
            supportId: currentSupportId,
            activeChats: [chatId],
          },
          { root: true }
        ),
        dispatch('support/add_chat_to_history', { chatId }, { root: true }),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_ACTIVE_CHAT_CHANGE_IN_PROGRESS', false);
    }
  },

  async exit_active_chat({ commit, dispatch, rootGetters, state }) {
    if (state.activeChatChangeInProgress) return;

    const currentSupportId = rootGetters['support/currentSupport']?._id;
    if (!currentSupportId) return;

    commit('SET_ACTIVE_CHAT_CHANGE_IN_PROGRESS', true);
    commit('SET_CURRENT_CHAT_ID', 0);
    commit('ui/REMOVE_ACTION_IF_LAST', 'open-chat', { root: true });

    try {
      await dispatch(
        'support/set_active_chats',
        {
          supportId: currentSupportId,
          activeChats: [],
        },
        { root: true }
      );
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_ACTIVE_CHAT_CHANGE_IN_PROGRESS', false);
    }
  },

  async update_field({ commit }, payload) {
    return await chatsApi.updateField(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
