<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card class="upload-dialog">
      <v-card-title></v-card-title>

      <v-card-text>
        <div
          v-if="message"
          style="overflow-x: hidden; overflow-y: auto; max-height: 280px"
        >
          <ul style="list-style: none; padding: 0" class="file-list">
            <li class="file-list__item">
              <div v-if="isPhoto" style="position: relative">
                <div class="thumbnail">
                  <div class="thumbnail__container">
                    <div
                      class="thumbnail__image"
                      :style="{ 'background-image': `url(${photoThumbnail})` }"
                    ></div>
                  </div>
                </div>

                <div
                  style="
                    position: absolute;
                    top: 0.25rem;
                    right: 0.25rem;
                    background: rgba(69, 90, 100, 0.6);
                  "
                  class="d-flex align-center rounded"
                >
                  <v-btn small dark icon class="mr-1">
                    <v-icon @click="openImageEditor" small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-file-input
                    prepend-icon="mdi-sync"
                    @change="changeFile($event)"
                    v-model="inputs.file"
                    class="reload-btn mt-0"
                    hide-input
                    dense
                    dark
                  ></v-file-input>
                </div>
              </div>

              <div v-else-if="isDocument">
                <div class="d-flex align-center justify-space-between py-2">
                  <div
                    :class="{
                      'red--text':
                        changedFile && changedFile.status === 'error',
                    }"
                  >
                    <v-icon
                      dense
                      :color="
                        changedFile && changedFile.status === 'error'
                          ? 'red'
                          : ''
                      "
                      >mdi-file-outline</v-icon
                    >
                    <span
                      class="d-inline-block ml-1 text-truncate"
                      style="max-width: 240px; vertical-align: middle"
                      >{{ documentName }}</span
                    >
                  </div>

                  <div class="rounded d-flex align-center">
                    <v-file-input
                      prepend-icon="mdi-sync"
                      @change="changeFile($event)"
                      v-model="inputs.file"
                      class="reload-btn mt-0"
                      hide-input
                      dense
                    ></v-file-input>
                  </div>
                </div>
              </div>

              <div
                v-else-if="isVideo || isVideoNote"
                style="position: relative"
              >
                <div class="thumbnail">
                  <div class="thumbnail__container">
                    <video v-if="isVideo" class="thumbnail__video" controls>
                      <source
                        :src="blobContainerUrl + message.video.fileName"
                        :type="message.video.mimeType"
                      />
                      Your browser does not support the video element.
                    </video>

                    <video
                      v-else-if="isVideoNote"
                      class="thumbnail__video"
                      controls
                    >
                      <source
                        :src="blobContainerUrl + message.isVideoNote.fileName"
                        :type="message.isVideoNote.mimeType"
                      />
                      Your browser does not support the video element.
                    </video>
                  </div>
                </div>

                <div
                  style="
                    position: absolute;
                    top: 0.25rem;
                    right: 0.25rem;
                    background: rgba(69, 90, 100, 0.6);
                  "
                  class="d-flex align-center rounded"
                >
                  <v-file-input
                    prepend-icon="mdi-sync"
                    @change="changeFile($event)"
                    v-model="inputs.file"
                    class="reload-btn mt-0"
                    hide-input
                    dense
                    dark
                  ></v-file-input>
                </div>
              </div>

              <div v-else-if="isVoice">
                <audio controls style="width: 100%">
                  <source
                    :src="blobContainerUrl + message.voice.fileName"
                    :type="message.voice.mimeType"
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </li>
          </ul>
        </div>

        <div class="d-flex red--text" v-if="isPhotoTooLarge">
          Изображение размером больше 10 МБ можно отправить только в несжатом
          виде
        </div>

        <div v-if="isAddedPhoto" class="d-flex">
          <v-checkbox
            v-model="compressImage"
            label="Сжать изображение"
          ></v-checkbox>
        </div>

        <div :class="{ 'mt-6': !(changedFile && isPhoto) }">
          <v-textarea
            @keydown.enter.exact.prevent="handleSend"
            @paste="handlePaste"
            v-model="inputs.text"
            ref="textInput"
            class="textarea"
            :error="isTooLongCaption"
            :background-color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
            label="Написать описание..."
            auto-grow
            outlined
            dense
            hide-details
            rows="1"
            row-height="15"
          ></v-textarea>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" color="grey darken-1" text>Отменить</v-btn>
        <v-btn
          @click="handleSend"
          :disabled="isPhotoTooLarge"
          color="blue"
          text
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
    <image-editor
      :containerId="'upload-edit-dialog-image-editor'"
      :isOpen="isImageEditorOpen"
      :image="editedImage"
      :onClose="closeImageEditor"
      :onSave="changeFile"
    />
  </v-dialog>
</template>

<script>
import messagesApi from '@/api/messages';
import { getScreenshot } from '@/lib/utils';
import ImageEditor from '@/components/imageEditor/ImageEditor';
import { BLOB_CONTAINER_URL } from '@/lib/const';
import { convertBytesToMegabytes } from '../../lib/utils';

export default {
  name: 'UploadEditDialog',
  components: {
    ImageEditor,
  },

  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,
      tempFile: null,
      tempMessageId: null,
      changedFile: null,
      compressImage: true,

      inputs: {
        file: {},
        text: '',
      },

      isImageEditorOpen: false,
      editedImage: null,
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.inputs.text = this.message.text || '';

        this.$store.commit('ui/ADD_ACTION', 'open-upload-edit-dialog', {
          root: true,
        });
        setTimeout(() => this.$refs['textInput'].focus(), 100);
      } else {
        setTimeout(() => {
          this.changedFile = null;
          this.inputs.text = '';

          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'open-upload-edit-dialog',
            { root: true }
          );
          document.getElementById('messageInput').focus();
        }, 200);
      }
    },
  },

  computed: {
    show: {
      get() {
        return this.$store.state.ui.currentDialog.name === 'uploadEditDialog';
      },

      set(show) {
        if (!show)
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
      },
    },

    message() {
      return this.show ? this.$store.state.ui.currentDialog.data.message : null;
    },

    photoThumbnail() {
      if (!this.message) return '';
      return this.isPhoto && this.changedFile
        ? this.changedFile.thumbnail
        : this.blobContainerUrl + this.message.photo.fileName;
    },

    documentName() {
      if (!this.message) return '';
      return this.isDocument && this.changedFile
        ? this.changedFile.file.name
        : this.message.document.originalName;
    },

    isAddedPhoto() {
      return this.changedFile && this.isPhoto;
    },

    isPhoto() {
      if (!this.message) return '';
      return !!(this.changedFile
        ? this.changedFile.thumbnail
        : this.message.photo);
    },

    isDocument() {
      if (!this.message) return '';
      return !!(this.changedFile
        ? !this.changedFile.thumbnail
        : this.message.document);
    },

    isVideoNote() {
      if (!this.message) return '';
      return !!(this.changedFile
        ? !this.changedFile.thumbnail
        : this.message.videoNote);
    },

    isVoice() {
      if (!this.message) return '';
      return !!(this.changedFile
        ? !this.changedFile.thumbnail
        : this.message.voice);
    },

    isVideo() {
      if (!this.message) return '';
      return !!(this.changedFile
        ? !this.changedFile.thumbnail
        : this.message.video);
    },

    isTooLongCaption() {
      return !!(this.inputs.text && this.inputs.text.length > 1024);
    },

    isPhotoTooLarge() {
      return this.compressImage && this.isPhoto && this.changedFile?.size > 10;
    },

    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    replyToMessage: {
      get() {
        return this.$store.state.chat.replyToMessage;
      },

      set(message) {
        this.$store.commit('chat/SET_REPLY_TO_MESSAGE', message, {
          root: true,
        });
      },
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    hasReplyToMessage() {
      return (
        Object.keys(this.replyToMessage).length > 0 &&
        this.replyToMessage.chatId === this.currentChat.chatId
      );
    },
  },

  methods: {
    handlePaste(e) {
      const clipboardData = e.clipboardData || e.originalEvent.clipboardData;
      if (clipboardData.types.indexOf('Files') < 0) return;
      let files = [...clipboardData.files];

      if (files.length) {
        let firstFile = files[0];

        const thumbnail =
          firstFile.type.indexOf('image') === 0
            ? URL.createObjectURL(firstFile)
            : null;
        const screenshot = getScreenshot(firstFile);

        this.changedFile = {
          status: '',
          file: screenshot || firstFile,
          ...(thumbnail ? { thumbnail } : {}),
          size: convertBytesToMegabytes(firstFile.size),
        };
      }

      this.inputs.file = {};
    },

    handleSend() {
      if (this.isTooLongCaption) return;

      const isPhoto = this.isPhoto && !!this.currentChatId;
      const isDocument = this.isDocument && !!this.currentChatId;
      const changedFile = this.changedFile;
      const captionChanged = (this.message.text || '') !== this.inputs.text;
      const caption = captionChanged
        ? this.inputs.text
        : this.message.text || '';
      this.tempMessageId = this.message.messageId;

      (async () => {
        this.$store.commit(
          'ui/SET_CURRENT_DIALOG',
          { name: 'loaderDialog' },
          { root: true }
        );

        if (changedFile && isPhoto) {
          await this.editPhoto(this.tempMessageId, changedFile, caption);
        } else if (changedFile && isDocument) {
          await this.editDocument(this.tempMessageId, changedFile, caption);
        } else if (captionChanged) {
          await this.editMessageCaption(this.tempMessageId, caption);
        }

        if (this.tempFile) {
          this.$store.commit('ui/ADD_ACTION', 'open-upload-edit-dialog', {
            root: true,
          });
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            {
              name: 'uploadEditDialog',
              data: {
                message: this.currentChat.messages.filter(
                  x => x.messageId === this.tempMessageId
                )[0],
              },
            },
            { root: true }
          );

          this.changedFile = this.tempFile;
          this.tempFile = null;
        } else {
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
        }

        this.$nextTick(() => {
          this.$store.commit('ui/SET_SCROLL_TO_BOTTOM', true, {
            root: true,
          });
        });
      })();
    },

    editMessageCaption(messageId, caption) {
      return new Promise(async (resolve, reject) => {
        try {
          const tempCaption = this.inputs.text;
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false;
          this.inputs.text = '';
          this.replyToMessage = {};

          await messagesApi
            .editMessageCaption(messageId, this.currentChatId, tempCaption)
            .catch(e => {
              this.replyToMessage = tempReplyToMessage || {};
              this.inputs.text = tempCaption;

              console.error(e);
            });

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    editPhoto(messageId, photo, caption) {
      return new Promise(async (resolve, reject) => {
        try {
          const mediaType = this.compressImage ? 'photo' : 'document';
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false; //do we need this ?
          let tempPhoto = photo;
          this.replyToMessage = {};

          await messagesApi
            .editMessageMedia(
              messageId,
              this.currentChatId,
              mediaType,
              tempPhoto.file,
              caption
            )
            .catch(e => {
              this.replyToMessage = tempReplyToMessage || {};
              this.tempFile = tempPhoto;

              tempPhoto.status = 'error';
              console.error(e);
            });

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    editDocument(messageId, document, caption) {
      return new Promise(async (resolve, reject) => {
        try {
          let tempDocument = document;
          const tempReplyToMessage = this.hasReplyToMessage
            ? this.$store.state.chat.replyToMessage
            : false;
          this.replyToMessage = {};

          await messagesApi
            .editMessageMedia(
              messageId,
              this.currentChatId,
              'document',
              tempDocument.file,
              caption
            )
            .catch(e => {
              this.replyToMessage = tempReplyToMessage || {};
              this.tempFile = tempDocument;

              tempDocument.status = 'error';
              console.error(e);
            });

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },

    changeFile(file) {
      const thumbnail =
        file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

      this.changedFile = {
        status: '',
        file,
        ...(thumbnail ? { thumbnail } : {}),
        size: convertBytesToMegabytes(file.size),
      };

      this.inputs.file = {};
    },

    close() {
      this.show = false;
    },

    openImageEditor() {
      if (this.changedFile?.file) {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.editedImage = { URL: reader.result };
            this.isImageEditorOpen = true;
          },
          { once: true }
        );
        reader.readAsDataURL(this.changedFile.file);
      } else {
        this.editedImage = {
          URL: this.blobContainerUrl + this.message.photo.fileName,
        };
        this.isImageEditorOpen = true;
      }
    },

    closeImageEditor() {
      this.isImageEditorOpen = false;
    },
  },
};
</script>

<style lang="scss">
.upload-dialog {
  .textarea textarea {
    max-height: 84px !important;
  }

  .file-list {
    &__item {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .thumbnail {
    border-radius: 8px;
    overflow: hidden;

    &__container {
      cursor: pointer;
      background: #3d3d3d;
    }

    &__image {
      cursor: pointer;
      width: 100%;
      height: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3d3d3d;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }

    &__video {
      display: block;
      margin: 0 auto;
      text-align: center;
      max-width: 100%;
      height: 240px;
    }
  }

  .reload-btn {
    .v-input__prepend-outer {
      margin: 0;
    }

    .v-icon {
      font-size: 1rem;
    }
  }
}
</style>
