<template>
  <v-textarea
    v-model="text"
    class="text-field"
    :class="{ saved: isSaved }"
    v-bind:label="label"
    hide-details="auto"
    solo
    :error="error"
    :error-messages="errorMessages"
  ></v-textarea>
</template>
<script>
export default {
  name: 'TextAreaWithSave',

  props: {
    title: {
      type: String,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
    },
    dispatchType: {
      type: String,
    },
    commitType: {
      type: String,
    },
  },

  data() {
    return {
      timeout: null,
      debounce: 500,
      isSaved: false,
      error: true,
      errorMessages: [],
    };
  },

  computed: {
    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    text: {
      get() {
        return this.currentChat[this.type];
      },

      set(value) {
        clearTimeout(this.timeout);
        this.$store.commit(
          this.commitType,
          { chatId: this.currentChat.chatId, [this.type]: value },
          { root: true }
        );

        this.timeout = setTimeout(() => {
          let sendData = {
            chatId: this.currentChat.chatId,
            [this.type]: value,
            supportId: this.currentSupport._id,
          };

          this.$store
            .dispatch(this.dispatchType, sendData, { root: true })
            .then(res => {
              this.error = true;
              this.errorMessages = [];
              this.isSaved = true;
              setTimeout(() => {
                this.isSaved = false;
              }, 500);
            })
            .catch(e => {
              this.error = true;
              this.errorMessages = [
                'Інформація не збереглась, змініть текст, щоб ще раз відправити його на сервер.',
              ];

              setTimeout(() => {
                this.error = true;
                this.errorMessages = [];
              }, 5000);
            });
        }, this.debounce);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.text-field {
  transition: all 0.3s;

  &.saved {
    ::v-deep .v-input__slot {
      box-shadow: 0 3px 1px -2px rgb(0 160 136 / 50%),
        0 2px 2px 0 rgb(0 160 136 / 50%), 0 1px 5px 0 rgb(0 160 136 / 50%) !important;
    }
  }
}
</style>
