export const SEARCH_BY_FIELDS = [
  {
    text: 'ID',
    value: 'id',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Номер телефону',
    value: 'phone',
  },
  {
    text: "Ім'я",
    value: 'firstName',
  },
  {
    text: 'Прізвище',
    value: 'lastName',
  },
  {
    text: 'Дата народження',
    value: 'birthDate',
  },
  {
    text: 'Номер рахунку',
    value: 'bankAccountNumber',
  },
  {
    text: 'Pesel',
    value: 'pesel',
  },
  {
    text: 'Номер паспорта',
    value: 'passportId',
  },
];

export const TABLE_HEADERS = [
  {
    text: 'ID',
    value: 'id',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Номер телефону',
    value: 'phone',
  },
  {
    text: "Ім'я",
    value: 'firstName',
  },
  {
    text: 'Прізвище',
    value: 'lastName',
  },
  {
    text: 'Номер рахунку',
    value: 'bankAccountNumber',
  },
  {
    text: 'Виплати Delivery',
    value: 'payFrequencyDelivery',
  },
  {
    text: 'Виплати Taxi',
    value: 'payFrequencyTaxi',
  },
  {
    text: 'UberId',
    value: 'uberId',
  },
  {
    text: 'BoltId',
    value: 'boltId',
  },
  {
    text: 'FreeNowId',
    value: 'freeNowId',
  },
  {
    text: 'UberEatsId',
    value: 'uberEatsId',
  },
  {
    text: 'BoltFoodId',
    value: 'boltFoodId',
  },
  {
    text: 'GlovoId',
    value: 'glovoId',
  },
  {
    text: 'Номерний знак',
    value: 'vehicleRegistration',
  },
  {
    text: 'Umowa zlecenia',
    value: 'isContract',
  },
  {
    text: 'Etat',
    value: 'contractType',
  },
  {
    text: 'Студент',
    value: 'isStudent',
  },
  {
    text: 'StuardId',
    value: 'stuartId',
  },
  {
    text: 'WoltId',
    value: 'woltId',
  },
  {
    text: 'Комісія без док',
    value: 'isDocumentMissing',
  },
  {
    text: 'Виплата готівкою',
    value: 'isCashPay',
  },
];

export const getTextAreasWithSave = isChatsV2Enabled => [
  {
    name: 'Информация',
    type: 'info',
    dispatchType: 'chat/set_info',
    commitType: isChatsV2Enabled ? 'chat/SET_INFO_V2' : 'chat/SET_INFO',
    placeholder: 'Информацию впишите сюда...',
  },
  {
    name: 'Заметка',
    type: 'additionalInfo',
    dispatchType: 'chat/set_additional_info',
    commitType: isChatsV2Enabled
      ? 'chat/SET_ADDITIONAL_INFO_V2'
      : 'chat/SET_ADDITIONAL_INFO',
    placeholder: 'Заметку впишите сюда...',
  },
];

export const TAG_OPTIONS = [
  { label: 'Коморник', value: 'bailiff' },
  { label: 'Выплата наличными', value: 'cash' },
];
