import axios from 'axios';
import { mapMediaMessagesToUnifiedMedia } from '@/lib/mediaUtils';

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const chatsApiURL = `${origin}/${prefix}/chats`;

const routes = {
  get: {
    chats: `${chatsApiURL}`,
    chat: `${chatsApiURL}/{id}`,
    counts: `${chatsApiURL}/counts`,
  },

  post: {
    info: `${chatsApiURL}/{id}/info`,
    additionalInfo: `${chatsApiURL}/{id}/additional-info`,
    credentials: `${chatsApiURL}/{id}/credentials`,
    userIds: `${chatsApiURL}/{id}/userIds`,
    approve: `${chatsApiURL}/{id}/approve`,
    block: `${chatsApiURL}/{id}/block`,
    vip: `${chatsApiURL}/{id}/vip`,
    read: `${chatsApiURL}/{id}/read`,
    enter: `${chatsApiURL}/{id}/enter`,
    leave: `${chatsApiURL}/{id}/leave`,
    departments: `${chatsApiURL}/{id}/departments`,
    startChat: `${chatsApiURL}/{id}/start-chat`,
    endChat: `${chatsApiURL}/{id}/end-chat`,
    updateField: `${chatsApiURL}/{id}/update-field`,
  },
};

const getRoutes = () => {
  return routes;
};

const getAll = async (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.chats + params;
  return axios.get(url);
};

const getOne = id => {
  const url = routes.get.chat.replace('{id}', id);
  return axios.get(url);
};

const getCounts = () => {
  const url = routes.get.counts;
  return axios.get(url);
};

const info = (id, info, supportId = null) => {
  const url = routes.post.info.replace('{id}', id);
  let data = { info, supportId };

  return axios.post(url, data);
};

const additionalInfo = (id, additionalInfo, supportId = null) => {
  const url = routes.post.additionalInfo.replace('{id}', id);
  let data = { additionalInfo, supportId };

  return axios.post(url, data);
};

const credentials = (id, credentials, supportId = null) => {
  const url = routes.post.credentials.replace('{id}', id);
  let data = { credentials, supportId };

  return axios.post(url, data);
};

const setUserIds = (id, userIds, supportId = null) => {
  const url = routes.post.userIds.replace('{id}', id);
  const data = { userIds, supportId };

  return axios.post(url, data);
};

const approve = (id, supportId) => {
  const url = routes.post.approve.replace('{id}', id);
  let data = { supportId };

  return axios.post(url, data);
};

const block = (id, supportId) => {
  const url = routes.post.block.replace('{id}', id);
  let data = { supportId };

  return axios.post(url, data);
};

const vip = (id, isVIP) => {
  const url = routes.post.vip.replace('{id}', id);
  let data = { isVIP };

  return axios.post(url, data);
};

const read = (id, supportId, isRead) => {
  const url = routes.post.read.replace('{id}', id);
  let data = { supportId, isRead };

  return axios.post(url, data);
};

const departments = (id, departments) => {
  const url = routes.post.departments.replace('{id}', id);
  let data = { departments };

  return axios.post(url, data);
};

const startChat = id => {
  const url = routes.post.startChat.replace('{id}', id);
  return axios.post(url, {});
};

const endChat = id => {
  const url = routes.post.endChat.replace('{id}', id);
  return axios.post(url, {});
};

const getMedia = async (id, mediaType, params) => {
  const { limit = 20, skip = 0 } = params;
  const url = `${chatsApiURL}/${id}/${mediaType}?limit=${limit}&skip=${skip}`;
  const { data } = await axios.get(url);
  return mapMediaMessagesToUnifiedMedia(data, mediaType);
};

const updateField = ({ chatId, key, value }) => {
  const url = routes.post.updateField.replace('{id}', chatId);
  const data = { key, value };

  return axios.post(url, data);
};

export default {
  getRoutes,
  getAll,
  getOne,
  getCounts,
  info,
  additionalInfo,
  credentials,
  setUserIds,
  approve,
  block,
  vip,
  read,
  departments,
  startChat,
  endChat,
  getMedia,
  updateField,
};
