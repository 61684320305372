import supportsApi from '@/api/supports';
import { getObjectByKeyValue } from '@/lib/utils';
import ChatHistoryCollection from '@/lib/ChatHistoryCollection';
import Vue from 'vue';

const state = {
  supports: [],
  chatHistory: new Map(),
};

const getters = {
  currentSupport(state, getters, rootState, rootGetters) {
    return (
      state.supports.filter(s => s._id === rootGetters['auth/user']._id)[0] ||
      {}
    );
  },
  getSupportByAzureId: state => supportAzureId => {
    return (
      state.supports.find(
        support => support.azureProviderId === supportAzureId
      ) || {}
    );
  },
};

const mutations = {
  SET_SUPPORTS(state, { supports }) {
    const supportIds = state.supports.map(support => support.supportId);

    supports.forEach(support => {
      if (supportIds.indexOf(support.supportId) < 0)
        state.supports.push(support);
    });
  },

  SET_ACTIVE_CHATS(state, { supportId, activeChats, triggeredViaSocket }) {
    if (triggeredViaSocket) {
      const currentUser = JSON.parse(window.localStorage.getItem('user'));
      if (supportId === currentUser?._id) return;
    }

    let support = getObjectByKeyValue(state.supports, supportId, '_id');
    if (support) support.activeChats = activeChats;
  },

  ADD_TO_CHAT_HISTORY(state, chatHistoryItem) {
    const newMap = new Map(state.chatHistory);
    newMap.set(chatHistoryItem.chatId, chatHistoryItem);
    state.chatHistory = newMap;
  },

  SET_CHAT_HISTORY(state, chatHistoryItems) {
    const chatHistoryMap = new Map();

    chatHistoryItems.forEach(chatHistoryItem => {
      chatHistoryMap.set(chatHistoryItem.chatId, chatHistoryItem);
    });

    state.chatHistory = chatHistoryMap;
  },

  CLEAR_CHAT_HISTORY(state) {
    state.chatHistory = new Map();
  },
};

const actions = {
  //Api calls
  async get_supports({ commit }) {
    try {
      const { data } = await supportsApi.getAll({
        limit: 200000,
        skip: 0,
      });

      commit('SET_SUPPORTS', { supports: data });
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  get_support({ commit, state }, supportId) {
    return new Promise(async (resolve, reject) => {
      try {
        let supportResponse = await supportsApi.getOne(supportId);
        let support = supportResponse.data;
        if (support.messages && support.messages.length === 0)
          throw 'Chat has no messages';

        commit('ADD_SUPPORT', support);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  set_active_chats({ commit }, payload) {
    const { supportId, activeChats } = payload;
    commit('SET_ACTIVE_CHATS', { supportId, activeChats });
    return supportsApi.chats(supportId, activeChats);
  },

  async add_chat_to_history({ commit }, { chatId }) {
    const chatHistoryItem = {
      chatId: chatId,
      createdAt: new Date().getTime(),
    };

    await ChatHistoryCollection.put(chatHistoryItem);
    commit('ADD_TO_CHAT_HISTORY', chatHistoryItem);
  },

  async remove_chat_history({ commit }) {
    await ChatHistoryCollection.clear();
    commit('CLEAR_CHAT_HISTORY');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
