const state = {
  actions: [
    //open-chat
    //open-photo //own ESC handling
    //open-context-menu //own ESC handling
    //open-message-templates-dialog
    //open-message-templates-create-dialog //own ESC handling
    //open-message-templates-edit-dialog
    //open-forward-message-dialog
    //open-upload-dialog
    //open-upload-edit-dialog
    //open-comment-dialog
    //open-client-records-dialog
    //add-selected-message
    //add-edit-message
    //open-history-dialog
  ],

  showInfoBar: JSON.parse(window.localStorage.getItem('showInfoBar')) || false,
  dialogNames: [
    'messageTemplatesCreateDialog',
    'messageTemplatesEditDialog',
    'messageTemplatesDialog',
    'forwardMessageDialog',
    'uploadDialog',
    'uploadEditDialog',
    'commentDialog',
    'loaderDialog',
    'clientRecordsDialog',
    'historyDialog',
  ],
  currentDialog: {}, //{name, data}
  scrollToBottom: false,
  scrollingToMessage: false,
};

const getters = {
  lastAction(state) {
    return state.actions[state.actions.length - 1] || '';
  },

  hasDialog(state) {
    return Object.keys(state.currentDialog).length > 0;
  },
};

const mutations = {
  ADD_ACTION(state, action) {
    if (state.actions.indexOf(action) < 0) state.actions.push(action);
  },

  REMOVE_ACTION_IF_LAST(state, action) {
    if (
      state.actions.length > 0 &&
      state.actions[state.actions.length - 1] === action
    )
      state.actions.splice(state.actions.length - 1, 1);
  },

  SET_SHOW_INFO_BAR(state, showState) {
    window.localStorage.setItem('showInfoBar', showState);
    state.showInfoBar = showState;
  },

  SET_CURRENT_DIALOG(state, dialog = {}) {
    state.currentDialog = dialog;
  },

  SET_CURRENT_DIALOG_DATA(state, data) {
    if (state.currentDialog.hasOwnProperty('data'))
      state.currentDialog.data = data;
  },

  SET_SCROLL_TO_BOTTOM(state, value) {
    state.scrollToBottom = value;
  },

  SET_SCROLLING_TO_MESSAGE(state, data) {
    state.scrollingToMessage = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
