<template>
  <v-app>
    <v-dialog v-model="loadingApp" persistent width="360">
      <v-card color="primary" dark class="pt-2">
        <v-card-text>
          Завантажую дані. Зачекайте, будь ласка.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-fade-transition v-if="showLoginModal">
      <div class="connection-alert">
        <v-alert dense type="red" icon="mdi-wifi-off" border="left">
          {{ errorMessage }}
        </v-alert>
      </div>
    </v-fade-transition>

    <v-fade-transition v-if="showConnectionAlert">
      <div class="connection-alert">
        <v-alert dense type="warning" icon="mdi-wifi-off" border="left">
          Проблеми з інтернетом, перезагрузіть сторінку!
        </v-alert>
      </div>
    </v-fade-transition>

    <actions-bar></actions-bar>

    <navigation-bar-v2 v-if="isChatsV2Enabled" />
    <navigation-bar v-else />

    <v-main class="main-background" :class="{ dark: darkMode }">
      <messages
        v-if="messages.length"
        :maxHeight="`calc(100vh - (106px + ${messageBarHeight}px) )`"
      ></messages>
      <div
        v-else
        style="
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <v-alert :outlined="darkMode" :text="darkMode" type="info"
          >Щоб розпочати переписку, виберіть чат</v-alert
        >
      </div>
    </v-main>

    <info-bar />
    <message-bar
      ref="messageBar"
      @updateMessageBarHeight="messageBarHeight = $event"
    />
  </v-app>
</template>

<script>
import ActionsBar from '@/components/common/bars/ActionsBar';
import NavigationBar from '@/components/common/bars/navigationBar/NavigationBar';
import NavigationBarV2 from '../components/common/bars/navigationBar/NavigationBarV2';
import InfoBar from '@/components/common/bars/infoBar/InfoBar';
import MessageBar from '@/components/common/bars/MessageBar';
import Messages from '@/components/Messages';
import SocketController from '@/lib/SocketController';
import { getObjectByKeyValue } from '@/lib/utils';
import ChatHistoryCollection from '@/lib/ChatHistoryCollection';

export default {
  name: 'Home',
  components: {
    ActionsBar,
    NavigationBar,
    NavigationBarV2,
    InfoBar,
    MessageBar,
    Messages,
  },

  data() {
    return {
      tempSocketMessages: [],
      isSynchronized: false,
      messageBarHeight: 0, // Define the messageBarHeight property
      messageDeleteQueue: new Map(),
      showConnectionAlert: false,
      showLoginModal: false,
      errorMessage: '',
      loadingApp: true,
    };
  },
  mounted() {
    const messageBarElement = this.$refs.messageBar;

    if (messageBarElement) {
      const messageBarHeight = messageBarElement.clientHeight;

      this.messageBarHeight = messageBarHeight;
    }
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },

    chats() {
      return this.$store.state.chat.chats;
    },

    byId() {
      return this.$store.state.chat.byId;
    },

    messages() {
      return this.$store.getters['chat/messages'];
    },

    lastAction() {
      return this.$store.getters['ui/lastAction'];
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    isChatsV2Enabled() {
      return this.$store.getters['feature/isFeatureEnabled']('CHATS_V2');
    },
  },

  created() {
    (async () => {
      await this.getData();
      this.syncSocketMessagesWithHttpData();
      await this.setChatHistory();

      this.loadingApp = false;

      console.log('chats', this.chats.length);
    })();

    document.addEventListener('keydown', async e => {
      e = e || window.event;
      //escape
      if (e.keyCode === 27) {
        if (this.$store.state.ui.actions.length < 1) {
          if (this.$store.state.chat.currentFolder !== 'all')
            this.$store.commit('chat/SET_CURRENT_FOLDER', 'all', {
              root: true,
            });
        } else {
          if (this.lastAction === 'open-chat') {
            if (this.$store.state.chat.currentChatId > 0) {
              await this.$store.dispatch('chat/exit_active_chat', null, {
                root: true,
              });
            }
          } else if (this.lastAction === 'open-message-templates-dialog') {
            this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
            this.$store.commit(
              'ui/REMOVE_ACTION_IF_LAST',
              'open-message-templates-dialog',
              { root: true }
            );
          } else if (this.lastAction === 'add-selected-message') {
            this.$store.commit('chat/SET_SELECTED_MESSAGES', [], {
              root: true,
            });
            this.$store.commit(
              'ui/REMOVE_ACTION_IF_LAST',
              'add-selected-message',
              { root: true }
            );
          } else if (this.lastAction === 'add-edit-message') {
            this.$store.commit('chat/SET_MESSAGE_INPUT', '', { root: true });
            this.$store.commit('chat/SET_CURRENT_MESSAGE', {}, { root: true });
            this.$store.commit('chat/SET_CHAT_MODE', '', { root: true });

            this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'add-edit-message', {
              root: true,
            });
          }
        }
      }
    });
  },

  methods: {
    async setChatHistory() {
      await ChatHistoryCollection.init();

      let chatHistoryItems = await ChatHistoryCollection.getAll();
      const oneDay = 24 * 60 * 60 * 1000;
      let chatHistoryItemsToRemove = [];

      chatHistoryItems.forEach((x, i) => {
        if (new Date().getTime() - x.createdAt >= oneDay)
          chatHistoryItemsToRemove.push({ chatId: x.chatId, index: i });
      });

      if (chatHistoryItemsToRemove.length) {
        for (let i = 0; i < chatHistoryItemsToRemove.length; i++) {
          await ChatHistoryCollection.remove(
            chatHistoryItemsToRemove[i].chatId
          );
          chatHistoryItems.splice(chatHistoryItemsToRemove[i].index, 1);
        }
      }

      this.$store.commit('support/SET_CHAT_HISTORY', chatHistoryItems, {
        root: true,
      });
    },

    syncSocketMessagesWithHttpData() {
      while (this.tempSocketMessages.length > 0) {
        let tempMessage = this.tempSocketMessages.shift();

        const messageDeleteMethod = this.isChatsV2Enabled
          ? this.handleMessageDeleteV2
          : this.handleMessageDelete;

        tempMessage.actionName === 'message:delete'
          ? messageDeleteMethod(tempMessage.message)
          : this.$store.commit(
              tempMessage.storeAction,
              tempMessage.message.data,
              { root: true }
            );

        tempMessage.actionName === 'message-template:delete'
          ? this.handleMessageTemplateDelete(tempMessage.message)
          : this.$store.commit(
              tempMessage.storeAction,
              tempMessage.message.data,
              { root: true }
            );
      }

      // this here is UNREAL scenario, cause JS is a one stream language
      // but sync logic is so critical, just leave it here, mb remove later
      if (this.tempSocketMessages.length > 0) {
        console.log('sync renew');
        this.syncSocketMessagesWithHttpData();
        return;
      }

      console.log('sync done');
      this.isSynchronized = true;
    },

    async getData() {
      this.initSocketListeners();

      await this.getChats();
      await this.$store.dispatch('support/get_supports', {}, { root: true });
      await this.$store.dispatch('chat/get_chat_counts', {}, { root: true });
      await this.$store.dispatch(
        'messageTemplates/get_message_templates',
        {},
        { root: true }
      );
      await this.$store.dispatch(
        'chat/get_message_reply_wait_time',
        {},
        { root: true }
      );
    },

    async getChats() {
      try {
        const limit = 5000;
        let skip = 0;
        let prevChatLength = 0;

        while (true) {
          await this.$store.dispatch(
            'chat/get_chats',
            { limit, skip },
            { root: true }
          );
          skip = this.chats.length;

          if (prevChatLength === this.chats.length) break;

          prevChatLength = this.chats.length;
        }

        if (this.isChatsV2Enabled) {
          this.$store.commit('chat/NORMALIZE_CHATS');
        }
      } catch (e) {
        console.log(e);
        this.loadingApp = false;
        this.showLoginModal = true;

        if (e.response.status === 401) {
          this.errorMessage = 'Вийдіть та перелогіньтесь, будь ласка.';
          return;
        }

        this.errorMessage =
          'Не зміг прогрузити чати =(. Спробуйте перелогінитись.';

        console.error('Не зміг прогрузити чати =(', e);
      }
    },

    registerSocketMessageHandler(actionName, storeAction) {
      SocketController.socket.on(actionName, message => {
        if (
          message.data.hasOwnProperty('sentBy') &&
          message.data.sentBy === this.currentSupport._id
        )
          return;

        const messageData = {
          triggeredViaSocket: true,
          ...(message?.data || {}),
        };

        this.isSynchronized
          ? this.$store.commit(storeAction, messageData, { root: true })
          : this.tempSocketMessages.push({ actionName, storeAction, message });
      });
    },

    initSocketListeners() {
      SocketController.socket.on('disconnect', () => {
        console.log('disconnect disc');
        this.showConnectionAlert = true;
      });

      SocketController.socket.on('reconnect_attempt', attempt => {
        console.log('reconnect_attempt disc');
        this.showConnectionAlert = true;
      });

      SocketController.socket.on('reconnect', attempt => {
        console.log('reconnect disc');
        this.showConnectionAlert = true;
      });

      SocketController.socket.on('reconnect_error', attempt => {
        console.log('reconnect_error disc');
        this.showConnectionAlert = true;
      });

      SocketController.socket.on('reconnect_failed', attempt => {
        console.log('reconnect_failed disc');
        this.showConnectionAlert = true;
      });

      // handle the event sent with socket.emit()
      const initChatSocketListeners = this.isChatsV2Enabled
        ? this.initChatSocketListenersV2
        : this.initChatSocketListeners;

      const initMessageSocketListeners = this.isChatsV2Enabled
        ? this.initMessageSocketListenersV2
        : this.initMessageSocketListeners;

      initChatSocketListeners();
      initMessageSocketListeners();
      this.initSupportSocketListeners();
      this.initMessageTemplateSocketListeners();
    },

    initChatSocketListeners() {
      this.registerSocketMessageHandler('chat:new', 'chat/ADD_CHAT');
      this.registerSocketMessageHandler('chat:info', 'chat/SET_INFO');
      this.registerSocketMessageHandler('chat:update', 'chat/UPDATE_CHAT');
      this.registerSocketMessageHandler(
        'chat:additional-info',
        'chat/SET_ADDITIONAL_INFO'
      );
      this.registerSocketMessageHandler(
        'chat:credentials',
        'chat/SET_CREDENTIALS'
      );
      this.registerSocketMessageHandler('chat:userIds', 'chat/SET_USER_IDS');
      this.registerSocketMessageHandler('chat:vip', 'chat/SET_VIP_STATUS');
      this.registerSocketMessageHandler('chat:read', 'chat/SET_READ_STATUS');
      this.registerSocketMessageHandler('chat:approve', 'chat/SET_APPROVED_BY');
      this.registerSocketMessageHandler('chat:block', 'chat/SET_BLOCKED_BY');
      this.registerSocketMessageHandler(
        'chat:departments',
        'chat/SET_DEPARTMENTS'
      );
      this.registerSocketMessageHandler(
        'chat:language',
        'chat/SET_CHAT_LANGUAGE'
      );
      this.registerSocketMessageHandler('chat:counts', 'chat/SET_CHAT_COUNTS');
      this.registerSocketMessageHandler(
        'chat:sort-message',
        'chat/SET_CHAT_SORT_MESSAGE'
      );
      this.registerSocketMessageHandler(
        'chat:update-field',
        'chat/UPDATE_FIELD'
      );
    },

    initChatSocketListenersV2() {
      this.registerSocketMessageHandler('chat:new', 'chat/ADD_CHAT_V2');
      this.registerSocketMessageHandler('chat:info', 'chat/SET_INFO_V2');
      this.registerSocketMessageHandler('chat:update', 'chat/UPDATE_CHAT_V2');
      this.registerSocketMessageHandler(
        'chat:additional-info',
        'chat/SET_ADDITIONAL_INFO_V2'
      );
      this.registerSocketMessageHandler(
        'chat:credentials',
        'chat/SET_CREDENTIALS_V2'
      );
      this.registerSocketMessageHandler('chat:userIds', 'chat/SET_USER_IDS_V2');
      this.registerSocketMessageHandler('chat:vip', 'chat/SET_VIP_STATUS_V2');
      this.registerSocketMessageHandler('chat:read', 'chat/SET_READ_STATUS_V2');
      this.registerSocketMessageHandler(
        'chat:approve',
        'chat/SET_APPROVED_BY_V2'
      );
      this.registerSocketMessageHandler('chat:block', 'chat/SET_BLOCKED_BY_V2');
      this.registerSocketMessageHandler(
        'chat:departments',
        'chat/SET_DEPARTMENTS_V2'
      );
      this.registerSocketMessageHandler(
        'chat:language',
        'chat/SET_CHAT_LANGUAGE_V2'
      );
      this.registerSocketMessageHandler('chat:counts', 'chat/SET_CHAT_COUNTS');
      this.registerSocketMessageHandler(
        'chat:sort-message',
        'chat/SET_CHAT_SORT_MESSAGE_V2'
      );
      this.registerSocketMessageHandler(
        'chat:update-field',
        'chat/UPDATE_FIELD_V2'
      );
    },

    initMessageSocketListeners() {
      this.registerSocketMessageHandler('message:new', 'chat/ADD_CHAT_MESSAGE');
      this.registerSocketMessageHandler('message:edit', 'chat/EDIT_MESSAGE');
      this.registerSocketMessageHandler(
        'message:comment',
        'chat/SET_MESSAGE_COMMENT'
      );
      this.registerSocketMessageHandler(
        'message:color',
        'chat/SET_MESSAGE_COLOR'
      );
      this.registerSocketMessageHandler(
        'message:unread-count',
        'chat/SET_UNREAD_MESSAGES_COUNT'
      );
      this.registerSocketMessageHandler(
        'message:reply-wait-time',
        'chat/SET_MESSAGE_REPLY_WAIT_TIME'
      );

      SocketController.socket.on('message:delete', message => {
        this.isSynchronized
          ? this.handleMessageDelete(message)
          : this.tempSocketMessages.push({
              actionName: 'message:delete',
              storeAction: '',
              message,
            });
      });
    },

    initMessageSocketListenersV2() {
      this.registerSocketMessageHandler(
        'message:new',
        'chat/ADD_CHAT_MESSAGE_V2'
      );
      this.registerSocketMessageHandler('message:edit', 'chat/EDIT_MESSAGE_V2');
      this.registerSocketMessageHandler(
        'message:comment',
        'chat/SET_MESSAGE_COMMENT_V2'
      );
      this.registerSocketMessageHandler(
        'message:color',
        'chat/SET_MESSAGE_COLOR_V2'
      );
      this.registerSocketMessageHandler(
        'message:unread-count',
        'chat/SET_UNREAD_MESSAGES_COUNT_V2'
      );
      this.registerSocketMessageHandler(
        'message:reply-wait-time',
        'chat/SET_MESSAGE_REPLY_WAIT_TIME'
      );

      SocketController.socket.on('message:delete', message => {
        this.isSynchronized
          ? this.handleMessageDeleteV2(message)
          : this.tempSocketMessages.push({
              actionName: 'message:delete',
              storeAction: '',
              message,
            });
      });
    },

    initMessageTemplateSocketListeners() {
      this.registerSocketMessageHandler(
        'message-template:new',
        'messageTemplates/ADD_MESSAGE_TEMPLATE'
      );

      SocketController.socket.on('message-template:edit', message => {
        this.isSynchronized
          ? this.$store.commit(
              'messageTemplates/EDIT_MESSAGE_TEMPLATE',
              message.data,
              { root: true }
            )
          : this.tempSocketMessages.push({
              actionName: 'message-template:edit',
              storeAction: 'messageTemplates/EDIT_MESSAGE_TEMPLATE',
              message,
            });
      });

      SocketController.socket.on('message-template:delete', message => {
        if (
          message.data.hasOwnProperty('sentBy') &&
          message.data.sentBy === this.currentSupport._id
        )
          return;

        this.isSynchronized
          ? this.handleMessageTemplateDelete(message)
          : this.tempSocketMessages.push({
              actionName: 'message-template:delete',
              storeAction: '',
              message,
            });
      });
    },

    initSupportSocketListeners() {
      this.registerSocketMessageHandler(
        'support:active-chats',
        'support/SET_ACTIVE_CHATS'
      );
    },

    handleMessageTemplateDelete(message) {
      if (
        this.$store.getters['messageTemplates/messageTemplates'].length === 1
      ) {
        this.$store.commit('messageTemplates/SET_CURRENT_TAB', 0, {
          root: true,
        });
        this.$store.commit('messageTemplates/SET_CURRENT_SUB_FOLDERS_TAB', 0, {
          root: true,
        });
      }

      this.$store.commit(
        'messageTemplates/DELETE_MESSAGE_TEMPLATE',
        {
          messageTemplateId: message.data.messageTemplateId,
        },
        { root: true }
      );
    },

    handleMessageDelete(message) {
      // why is message:delete so complex?
      // if supportA removes message that supportB hasn't
      // supportB has an error, so supportB must handle this situation
      // so this is handling situation

      const chat = getObjectByKeyValue(
        this.chats,
        message.data.chatId,
        'chatId'
      );

      if (chat && chat.messages.length === 1) {
        if (this.messageDeleteQueue.has(message.data.chatId)) {
          this.messageDeleteQueue.set(message.data.chatId, [
            ...this.messageDeleteQueue.get(message.data.chatId),
            message.data.messageId,
          ]);
          return;
        } else {
          this.messageDeleteQueue.set(message.data.chatId, [
            message.data.messageId,
          ]);
        }

        this.$store
          .dispatch(
            'chat/get_more_chat_messages',
            { chatId: message.data.chatId, skip: 0 },
            { root: true }
          )
          .then(res => {
            this.messageDeleteQueue
              .get(message.data.chatId)
              .forEach(messageId => {
                this.$store.commit(
                  'chat/DELETE_MESSAGE',
                  { chatId: message.data.chatId, messageId },
                  { root: true }
                );
              });

            this.messageDeleteQueue.delete(message.data.chatId);
          })
          .catch(e => {
            console.log('message:delete disc');
            this.showConnectionAlert = true;
          });
      } else {
        this.$store.commit('chat/DELETE_MESSAGE', message.data, { root: true });
      }
    },

    handleMessageDeleteV2(message) {
      const chat = this.byId[message.data.chatId];

      if (chat && chat.messages.length === 1) {
        if (this.messageDeleteQueue.has(message.data.chatId)) {
          this.messageDeleteQueue.set(message.data.chatId, [
            ...this.messageDeleteQueue.get(message.data.chatId),
            message.data.messageId,
          ]);
          return;
        } else {
          this.messageDeleteQueue.set(message.data.chatId, [
            message.data.messageId,
          ]);
        }

        this.$store
          .dispatch(
            'chat/get_more_chat_messages_v2',
            { chatId: message.data.chatId, skip: 0 },
            { root: true }
          )
          .then(res => {
            this.messageDeleteQueue
              .get(message.data.chatId)
              .forEach(messageId => {
                this.$store.commit(
                  'chat/DELETE_MESSAGE_V2',
                  { chatId: message.data.chatId, messageId },
                  { root: true }
                );
              });

            this.messageDeleteQueue.delete(message.data.chatId);
          })
          .catch(e => {
            console.log('message:delete disc');
            this.showConnectionAlert = true;
          });
      } else {
        this.$store.commit('chat/DELETE_MESSAGE_V2', message.data, {
          root: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.connection-alert {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 800px;
  z-index: 1000;
}

.main-background {
  background-image: url('/pattern-mask.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('/pattern.png');
    background-position: top right;
    background-size: 510px auto;
    background-repeat: repeat;
  }

  &.dark {
    background-image: none;
    background-color: rgba(10, 10, 10, 1);

    &::before {
      background-image: url('/pattern-dark.png');
    }
  }
}
</style>
