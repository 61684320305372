<template>
  <v-list-item
    @click="onClick"
    @contextmenu.prevent="onContextMenu"
    :class="{
      'support-on-chat': isCurrentChat,
      'is-client-last-message': isClientLastMessage,
      'is-vip': isVip,
    }"
    class="pl-1"
  >
    <v-list-item-content>
      <div class="d-flex justify-space-between pl-1">
        <div
          v-if="isActiveChat"
          style="position: absolute; top: 0; bottom: 0; left: 0; z-index: 1"
        >
          <div
            :class="isCurrentChat ? 'purple' : 'blue'"
            style="height: 100%; width: 4px; border-radius: 2px"
          ></div>
        </div>

        <div class="d-flex" style="max-width: 210px">
          <div style="position: relative" class="mr-2">
            <span
              v-if="isVip"
              style="
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
              "
              >👑
            </span>
            <v-icon
              v-if="blockedBy"
              color="red"
              style="
                position: absolute;
                z-index: 1;
                font-size: 44px;
                left: -1px;
                top: -1px;
              "
            >
              mdi-block-helper
            </v-icon>

            <v-avatar color="teal" size="42">
              <img
                v-if="avatarUrl"
                :src="avatarUrl"
                cache-control="max-age=86400"
                loading="lazy"
              />
              <span v-else class="white--text">{{
                name?.charAt(0).toUpperCase()
              }}</span>
            </v-avatar>

            <div
              class="d-flex align-center mt-0"
              style="position: absolute; bottom: -9px"
            >
              <div
                v-for="department in selectedDepartments"
                v-bind:key="department.id"
                :class="`mr-1 ${department.color}`"
                style="height: 8px; width: 8px; border-radius: 8px"
              ></div>
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="mb-1 font-weight-medium text-truncate">
              {{ name }}
            </div>
            <div class="text-truncate" style="max-width: 200px">
              {{ isClientLastMessage ? `${name}:` : 'Мен:' }}
              {{ messageInfo }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-body-2" style="line-height: 1">
            {{ dateLabel }}
          </div>
          <div class="text-center mt-2 mr-2">
            <v-badge
              v-if="unreadMessagesCount > 0"
              :color="unreadMessagesCount > 0 ? 'pink' : 'transparent'"
              :content="unreadMessagesCount"
            />
            <v-badge
              v-else-if="!isRead"
              :color="!isRead ? 'pink' : 'transparent'"
            />
          </div>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ChatListItem',

  props: {
    avatarUrl: String,
    blockedBy: {
      type: [String, Number],
    },
    chatId: Number,
    dateLabel: String,
    isActiveChat: Boolean,
    isClientLastMessage: Boolean,
    isCurrentChat: Boolean,
    isRead: Boolean,
    isVip: Boolean,
    messageInfo: String,
    name: String,
    selectedDepartments: Array,
    unreadMessagesCount: Number,
  },

  methods: {
    onClick() {
      this.$emit('click', this.chatId);
    },

    onContextMenu(event) {
      this.$emit('contextmenu', { event, chatId: this.chatId });
    },
  },
};
</script>

<style scoped lang="scss">
.is-client-last-message {
  background-color: rgba(0, 146, 255, 0.1);
}

.v-list-item {
  &.is-vip {
    background-color: rgba(255, 146, 255, 0.2) !important;
  }
}

.support-on-chat {
  background-color: rgba(69, 62, 181, 0.1);
}
</style>
