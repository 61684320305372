<template>
  <div style="padding-left: 80px">
    <div class="grey--text subtitle-2 text-center my-2">---Повідомлення---</div>
    <v-virtual-scroll
      :bench="6"
      :items="items"
      :height="'calc(100vh - 166px)'"
      item-height="67.20"
    >
      <template v-slot:default="{ item, index }">
        <search-message
          :chat="getChat(item.chatId)"
          :current-chat-id="currentChatId"
          :current-message-id="scrollToMessageId"
          :index="index"
          :item="item"
          :blobContainerUrl="blobContainerUrl"
          @message-click="onMessageClick"
        />
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import SearchMessage from './SearchMessage.vue';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'SeachMessageList',

  components: {
    SearchMessage,
  },

  props: {
    items: Array,
  },

  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,
    };
  },

  computed: {
    chats() {
      return this.$store.state.chat.chats;
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    scrollToMessageId() {
      return this.$store.state.chat.scrollToMessageId;
    },
  },

  methods: {
    getChat(chatId) {
      return this.chats.find(chat => chat.chatId === chatId) || {};
    },

    async onMessageClick(chatId, messageId) {
      this.$store.commit('ui/SET_SCROLLING_TO_MESSAGE', true);

      await this.$store.dispatch('chat/enter_active_chat', chatId, {
        root: true,
      });

      if (document.getElementById('messageInput'))
        document.getElementById('messageInput').focus();

      // TODO: this has to be at the end of this function as otherwise watcher in Messages.vue
      // does not react to the change - investigate when possible
      this.$store.commit('chat/SET_SCROLL_TO_MESSAGE_ID', { messageId });
    },
  },
};
</script>
