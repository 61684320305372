<template>
  <div v-if="loading">
    <v-skeleton-loader max-height="3rem" type="image" />
  </div>
  <v-chip-group v-else @change="handleTagChange" :value="value" column multiple>
    <v-chip
      active-class="primary"
      v-for="option in options"
      :key="option.value"
      filter
    >
      {{ option.label }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import { TAG_OPTIONS } from '../constants';

export default {
  name: 'TagSection',

  data() {
    return {
      loading: false,
      options: TAG_OPTIONS,
    };
  },

  computed: {
    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },
    value() {
      const { tags } = this.currentChat;

      return (
        tags?.map(tag =>
          this.options.findIndex(option => option.value === tag)
        ) || []
      );
    },
  },

  methods: {
    async handleTagChange(selection) {
      this.loading = true;

      try {
        const newTags = selection.map(idx => this.options[idx].value);
        await this.$store.dispatch(
          'chat/update_field',
          {
            chatId: this.currentChat.chatId,
            key: 'tags',
            value: newTags,
          },
          { root: true }
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
