<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Історія чатів <span style="font-size: 12px">(за добу)</span></span
        >
      </v-card-title>

      <v-card-text v-if="!loading">
        <div class="mb-6 px-6">
          <v-text-field
            v-model="searchInput"
            ref="searchInput"
            hide-details
            label="Пошук по first/last/user name"
            prepend-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </div>

        <div>
          <v-virtual-scroll
            :bench="20"
            :items="chats"
            height="400"
            item-height="67.20"
          >
            <template v-slot:default="{ item, index }">
              <v-list-item
                @click="setChat(item.chatId)"
                :key="item.chatId + index"
              >
                <v-list-item-content>
                  <div class="d-flex justify-space-between">
                    <div style="position: relative" class="mr-4">
                      <span
                        v-if="item.isVIP"
                        style="
                          position: absolute;
                          top: 0;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          z-index: 1;
                        "
                        >👑</span
                      >

                      <v-avatar color="teal" size="42">
                        <img
                          v-if="item.avatar"
                          :src="blobContainerUrl + item.avatar"
                        />
                        <span v-else class="white--text">{{
                          item.from.first_name.charAt(0).toUpperCase()
                        }}</span>
                      </v-avatar>
                    </div>

                    <div class="flex-grow-1">
                      <div class="mb-2 font-weight-medium">
                        {{ getName(item) }}
                      </div>
                      <div class="text-truncate" style="max-width: 200px">
                        {{
                          item.messages[item.messages.length - 1].supportId !==
                          null
                            ? 'Мен:'
                            : `${item.from.first_name}:`
                        }}
                        {{
                          messageInfo(item.messages[item.messages.length - 1])
                        }}
                      </div>
                    </div>

                    <div>
                      <div
                        v-if="
                          isToday(
                            new Date(
                              item.messages[item.messages.length - 1].createdAt
                            )
                          )
                        "
                        class="text-body-2"
                      >
                        {{
                          formatTime(
                            Math.round(
                              item.messages[item.messages.length - 1].createdAt
                            )
                          )
                        }}
                      </div>
                      <div v-else class="text-body-2">
                        {{
                          formatDate(
                            Math.round(
                              item.messages[item.messages.length - 1].createdAt
                            )
                          )
                        }}
                      </div>
                      <div class="text-center mt-2 mr-2">
                        <v-badge
                          :color="
                            item.unreadMessagesCount > 0
                              ? 'pink'
                              : 'transparent'
                          "
                          :content="item.unreadMessagesCount"
                        ></v-badge>
                      </div>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </div>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn @click="removeChatHistory" color="blue" text>Очистити</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'HistoryDialog',
  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,
      loading: false,
      searchInput: null,
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.$store.commit('ui/ADD_ACTION', 'open-history-dialog', {
          root: true,
        });
        setTimeout(() => this.$refs['searchInput'].focus(), 100);
      } else {
        setTimeout(() => {
          this.searchInput = null;
          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'open-history-dialog',
            { root: true }
          );
        }, 200);
      }
    },
  },

  computed: {
    byId() {
      return this.$store.state.chat.byId;
    },

    show: {
      get() {
        return this.$store.state.ui.currentDialog.name === 'historyDialog';
      },

      set(show) {
        if (!show)
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
      },
    },

    chatHistory() {
      return this.$store.state.support.chatHistory;
    },

    chats() {
      const historyChatIds = Array.from(this.chatHistory.keys());
      const historyChats = historyChatIds.map(chatId => this.byId[chatId]);

      if (!this.searchInput) {
        return historyChats.sort((chatOne, chatTwo) => {
          const chatOneTimestamp = this.chatHistory.get(
            chatOne.chatId
          )?.createdAt;
          const chatTwoTimestamp = this.chatHistory.get(
            chatTwo.chatId
          )?.createdAt;

          return chatTwoTimestamp - chatOneTimestamp;
        });
      }

      const searchPhraseLowercase = this.searchInput.toLocaleLowerCase();
      const [firstWord, secondWord] = searchPhraseLowercase.split(' ');

      return historyChats.filter(chat => {
        const firstName = chat.from.first_name?.toLocaleLowerCase();
        const lastName = chat.from.last_name?.toLocaleLowerCase();

        return (
          chat.userIds?.some(id =>
            id.toLocaleLowerCase().includes(searchPhraseLowercase)
          ) ||
          firstName?.includes(searchPhraseLowercase) ||
          lastName?.includes(searchPhraseLowercase) ||
          (firstName &&
            lastName &&
            firstName.includes(firstWord) &&
            lastName.includes(secondWord)) ||
          chat.from.username
            ?.toLocaleLowerCase()
            .includes(searchPhraseLowercase) ||
          chat.info?.toLocaleLowerCase().includes(searchPhraseLowercase) ||
          chat.additionalInfo
            ?.toLocaleLowerCase()
            .includes(searchPhraseLowercase)
        );
      });
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },
  },

  methods: {
    formatDate,
    formatTime,
    async removeChatHistory() {
      await this.$store.dispatch(
        'support/remove_chat_history',
        {},
        { root: true }
      );
    },

    messageInfo(message) {
      let text = message.text;

      switch (true) {
        case message.hasOwnProperty('photo'):
          text = 'Фото';
          break;

        case message.hasOwnProperty('document'):
          text = 'Документ';
          break;

        case message.hasOwnProperty('voice'):
          text = 'Голосове повідомлення';
          break;

        case message.hasOwnProperty('videoNote'):
          text = 'Відео повідомлення';
          break;

        case message.hasOwnProperty('video'):
          text = 'Відео';
          break;
      }

      return text;
    },

    getName(chat) {
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },

    async setChat(id) {
      if (this.currentChat.chatId === id) return;

      await this.$store.dispatch('chat/enter_active_chat', id, { root: true });

      this.close();
    },

    close() {
      this.show = false;
      if (document.getElementById('messageInput'))
        document.getElementById('messageInput').focus();
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog > .v-card > .v-card__text {
  padding-left: 0;
  padding-right: 0;
}

.v-list-item {
  padding: 0 1.5rem;
}
</style>
