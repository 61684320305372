<template>
  <v-navigation-drawer app width="400" permanent stateless>
    <folders-bar></folders-bar>

    <v-sheet
      class="d-flex justify-space-between align-center"
      height="72"
      style="padding-left: 80px"
    >
      <div class="flex-grow-1 py-2 px-4">
        <v-text-field v-model="searchInput" label="Поиск" clearable />
      </div>
      <search-type-dropdown
        :current-chat-id="currentChatId"
        :search-type="searchType"
      />
    </v-sheet>

    <div style="padding-left: 80px" v-if="searchType === SEARCH_TYPE.USER">
      <v-virtual-scroll
        :bench="6"
        :items="chatIds"
        :height="'calc(100vh - 128px)'"
        item-height="67.20"
      >
        <template v-slot:default="{ item: chatId }">
          <chat-list-item
            v-bind:key="chatId"
            @click="selectChat"
            @contextmenu="openMenu"
            :avatar-url="getAvatarUrl(byId[chatId].avatar)"
            :blocked-by="byId[chatId].blockedBy"
            :chat-id="chatId"
            :date-label="
              getDateLabel(
                byId[chatId]?.messages[byId[chatId]?.messages?.length - 1]
                  ?.createdAt
              )
            "
            :is-active-chat="supportActiveChats.has(chatId)"
            :is-client-last-message="
              isClientLastMessage(
                byId[chatId]?.messages[byId[chatId]?.messages?.length - 1]
              )
            "
            :is-current-chat="chatId === currentChatId"
            :is-read="byId[chatId].isRead"
            :is-vip="byId[chatId].isVIP"
            :message-info="
              messageInfo(
                byId[chatId]?.messages[byId[chatId]?.messages?.length - 1]
              )
            "
            :name="getName(byId[chatId])"
            :selected-departments="
              getSelectedDepartments(byId[chatId].departments)
            "
            :unread-messages-count="byId[chatId].unreadMessagesCount"
          />
        </template>
      </v-virtual-scroll>
    </div>
    <search-message-list v-else :items="searchedMessages" />

    <v-sheet height="56" style="padding-left: 80px">
      <div class="py-1 px-4">
        <v-btn @click="openHistoryDialog" class="my-2" outlined fab x-small>
          <v-icon>mdi-history</v-icon>
        </v-btn>
      </div>
    </v-sheet>

    <v-menu
      v-model="showMenu"
      :close-on-click="true"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list v-if="menuChat">
        <v-list-item disabled link>
          <v-list-item-icon class="mr-4">
            <v-icon disabled style="transform: scale(-1, 1)">mdi-heart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Додати в мої чати</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="sendReadStatus"
          link
          :disabled="!canChangeReadStatus()"
        >
          <v-list-item-icon class="mr-4">
            <v-icon
              style="transform: scale(-1, 1)"
              :disabled="!canChangeReadStatus()"
            >
              {{
                menuChat.isRead && menuChat.unreadMessagesCount === 0
                  ? 'mdi-message-text'
                  : 'mdi-message-text-outline'
              }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{
                menuChat.isRead && menuChat.unreadMessagesCount === 0
                  ? 'Позначити як непрочитаний'
                  : 'Позначити як прочитаний'
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-menu
          open-on-hover
          :close-on-click="true"
          :close-on-content-click="true"
          offset-x
          max-width="154"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" link>
              <v-list-item-icon class="mr-4">
                <v-icon style="transform: scale(-1, 1)">mdi-folder</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Додати в категорію</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list>
            <v-list-item
              v-for="folder in departmentFolders"
              v-bind:key="folder.name"
              @click="handleDepartmentsChange(folder.name)"
              link
            >
              <v-list-item-icon class="mr-4">
                <v-icon :color="hasDepartment(folder.name) ? 'blue' : ''">{{
                  folder.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class="{ 'blue--text': hasDepartment(folder.name) }"
                >{{ folder.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item @click="sendVipStatus" link>
          <v-list-item-icon class="mr-4">
            <v-icon>
              {{ menuChat.isVIP ? 'mdi-star-remove' : 'mdi-star-check' }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ menuChat.isVIP ? 'Забрати VIP' : 'Надати VIP' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="blockUser" link>
          <v-list-item-icon class="mr-4">
            <v-icon>
              {{
                menuChat.blockedBy ? 'mdi-account-check' : 'mdi-account-cancel'
              }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ menuChat.blockedBy ? 'Забрати БАН' : 'Надати БАН' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <history-dialog-v2 />
  </v-navigation-drawer>
</template>

<script>
import HistoryDialogV2 from '@/components/dialogs/HistoryDialogV2';
import FoldersBar from '@/components/common/bars/FoldersBar';
import SearchMessageList from './components/SearchMessageList.vue';
import SearchTypeDropdown from './components/SearchTypeDropdown.vue';
import ChatListItem from './components/chatListItem/ChatListItem.vue';
import messagesApi from '@/api/messages';
import { formatDate, formatTime, isToday } from '@/lib/utils';
import { SEARCH_TYPE } from '@/store/types';
import { BLOB_CONTAINER_URL } from '@/lib/const';
import debounce from 'lodash.debounce';

export default {
  name: 'NavigationBar',
  components: {
    ChatListItem,
    FoldersBar,
    HistoryDialogV2,
    SearchMessageList,
    SearchTypeDropdown,
  },

  data() {
    return {
      menuChat: null,
      showMenu: false,
      x: 0,
      y: 0,
      searchedMessages: [],

      blobContainerUrl: BLOB_CONTAINER_URL,
      SEARCH_TYPE,

      debouncedSearchMessages: null,
      debouncedSearchChatMessages: null,
    };
  },

  computed: {
    byId() {
      return this.$store.state.chat.byId;
    },

    chatIds() {
      return this.$store.getters['chat/filteredChatIds'];
    },

    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    supports() {
      return this.$store.state.support.supports;
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    departmentFolders() {
      return this.$store.getters['chat/departmentFolders'];
    },

    supportActiveChats() {
      let activeChats = new Set();

      this.supports
        .map(support => support.activeChats)
        .flat()
        .forEach(chat => {
          activeChats.add(chat);
        });

      return activeChats;
    },

    searchInput: {
      get() {
        return this.$store.state.chat.searchInput;
      },

      set(value) {
        let searchString = value ? value.trim() : '';
        this.$store.commit('chat/SET_SEARCH_INPUT', searchString, {
          root: true,
        });
      },
    },

    searchType() {
      return this.$store.state.chat.searchType;
    },

    lastAction() {
      return this.$store.getters['ui/lastAction'];
    },

    isChatsV2Enabled() {
      return this.$store.getters['feature/isFeatureEnabled']('CHATS_V2');
    },
  },

  watch: {
    showMenu(val) {
      val
        ? this.$store.commit('ui/ADD_ACTION', 'open-context-menu', {
            root: true,
          })
        : this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-context-menu', {
            root: true,
          });
    },

    searchInput() {
      if (this.searchType === SEARCH_TYPE.USER) return;

      if (
        this.$store.state.chat.searchInput.length &&
        this.searchType === SEARCH_TYPE.MESSAGE
      ) {
        this.debouncedSearchMessages();
      }

      if (
        this.$store.state.chat.searchInput.length &&
        this.searchType === SEARCH_TYPE.CHAT
      ) {
        this.debouncedSearchChatMessages();
      }

      if (this.$store.state.chat.searchInput.length === 0) {
        this.searchedMessages = [];
      }
    },
  },

  created() {
    document.addEventListener('keydown', e => {
      e = e || window.event;
      //escape
      if (e.keyCode === 27) {
        if (this.$store.state.ui.actions.length > 0) {
          if (this.lastAction === 'open-context-menu') {
            if (this.showMenu) this.closeMenu();
          }
        }
      }
    });

    this.debouncedSearchMessages = debounce(this.searchMessages, 350);
    this.debouncedSearchChatMessages = debounce(this.searchChatMessages, 350);
  },

  methods: {
    formatDate,
    formatTime,
    isToday,
    openHistoryDialog() {
      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'historyDialog',
          data: {},
        },
        { root: true }
      );
    },

    hasDepartment(department) {
      return this.menuChat.departments.indexOf(department) >= 0;
    },

    handleDepartmentsChange(department) {
      this.menuChat.departments.indexOf(department) >= 0
        ? this.removeDepartment(department)
        : this.addDepartment(department);
    },

    addDepartment(department) {
      if (this.menuChat.departments.indexOf(department) >= 0) return;

      const departments = [...this.menuChat.departments, department];

      this.$store.dispatch(
        'chat/set_departments',
        {
          chatId: this.menuChat.chatId,
          departments,
        },
        { root: true }
      );
    },

    removeDepartment(department) {
      if (this.menuChat.departments.indexOf(department) < 0) return;

      const departments = [...this.menuChat.departments];
      departments.splice(departments.indexOf(department), 1);

      this.$store.dispatch(
        'chat/set_departments',
        {
          chatId: this.menuChat.chatId,
          departments,
        },
        { root: true }
      );
    },

    canChangeReadStatus() {
      const supportsInChat = this.$store.state.support.supports.filter(
        support => support.activeChats.indexOf(this.menuChat.chatId) >= 0
      );
      const supportIds = supportsInChat.map(x => x._id);
      const supportsCount = supportIds.length;

      return (
        supportsCount === 0 ||
        (supportsCount === 1 &&
          supportIds.indexOf(
            this.$store.getters['support/currentSupport']._id
          ) >= 0)
      );
    },

    async sendReadStatus() {
      if (!this.canChangeReadStatus()) return;
      const isRead =
        this.menuChat.unreadMessagesCount === 0 && this.menuChat.isRead;

      //remove support from chat
      if (
        this.currentChatId > 0 &&
        this.currentChatId === this.menuChat.chatId
      ) {
        await this.$store.dispatch('chat/exit_active_chat', null, {
          root: true,
        });
      }

      this.$store.dispatch(
        'chat/set_read_status',
        {
          chatId: this.menuChat.chatId,
          supportId: this.currentSupport._id,
          isRead: !isRead,
        },
        { root: true }
      );

      if (this.showMenu) this.closeMenu();
    },

    openMenu({ event, chatId }) {
      if (this.showMenu) {
        this.closeMenu();
        return;
      }

      this.x = event.clientX;
      this.y = event.clientY;

      this.$nextTick(() => {
        this.menuChat = this.byId[chatId];
        this.showMenu = true;
      });
    },

    closeMenu() {
      if (!this.showMenu) return;

      this.showMenu = false;
      this.menuChat = null;
    },

    getName(chat) {
      const name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;

      return name.trim() || chat.from.username || 'No name';
    },

    messageInfo(message) {
      const { photo, document, voice, videoNote, video, text } = message;

      if (photo) {
        return 'Фото';
      }

      if (document) {
        return 'Документ';
      }

      if (voice) {
        return 'Голосовое сообщение';
      }

      if (videoNote) {
        return 'Видео сообщение';
      }

      if (video) {
        return 'Видео';
      }

      return text;
    },

    async selectChat(id) {
      await this.$store.dispatch('chat/enter_active_chat', id, { root: true });

      if (document.getElementById('messageInput'))
        document.getElementById('messageInput').focus();
    },

    sendVipStatus() {
      this.$store.dispatch(
        'chat/set_vip_status',
        {
          chatId: this.menuChat.chatId,
          isVIP: !this.menuChat.isVIP,
        },
        { root: true }
      );
      // closing menu here because VIP chat is moved to another folder and without closing
      // context menu would stay open on top of the wrong chat
      this.closeMenu();
    },

    blockUser() {
      this.$store.dispatch(
        'chat/set_blocked_by',
        {
          chatId: this.menuChat.chatId,
          supportId: this.currentSupport._id,
        },
        { root: true }
      );
    },

    getSelectedDepartments(selectedDepartmentsIds) {
      return this.departmentFolders.filter(({ name }) =>
        selectedDepartmentsIds.includes(name)
      );
    },

    getAvatarUrl(avatar) {
      if (!avatar) return avatar;

      return this.blobContainerUrl + avatar;
    },

    getDateLabel(lastMessageTimestamp) {
      if (isToday(lastMessageTimestamp)) {
        return formatTime(lastMessageTimestamp);
      }

      return formatDate(lastMessageTimestamp);
    },

    isClientLastMessage(lastMessage) {
      return lastMessage.supportId === null;
    },

    searchMessages() {
      messagesApi
        .search({ search: this.$store.state.chat.searchInput })
        .then(({ data }) => {
          this.searchedMessages = data;
        });
    },

    searchChatMessages() {
      messagesApi
        .searchChat(this.currentChatId, {
          search: this.$store.state.chat.searchInput,
        })
        .then(({ data }) => {
          this.searchedMessages = data;
        });
    },
  },
};
</script>
